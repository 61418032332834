import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-events-calendar',
  templateUrl: './skeleton-events-calendar.component.html',
  styleUrls: ['./skeleton-events-calendar.component.scss'],
})
export class SkeletonEventsCalendarComponent implements OnInit {
  @Input() rows: number = 7;
  constructor() {}

  ngOnInit(): void {}
}
