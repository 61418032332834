<footer *ngIf="dataNav.length !== 0">
  <div class="content">
    <div class="link-boxes">
      <ul class="box u-margin-top-1">
        <li class="link_name">ENLACES DE INTERÉS</li>
        <li *ngFor="let item of dataNav">
          <a [routerLink]="item.urlWeb">{{ item.title }}</a>
        </li>
        <li>
          <a routerLink="/public/FAQ">FAQ</a>
        </li>
        <li class="u-margin-top-3"><a>Redes Sociales</a></li>
        <li>
          <div class="media-icons">
            <div class="u-align-start" style="width: 100%">
              <button
                class="btn-transparent"
                *ngFor="let social of dataSocial"
                (click)="redirectSocialMedia(social.url)"
              >
                <svg>
                  <use
                    attr.xlink:href="assets/svg/icons.svg#{{social.logo==='svg-youtube'?'svg-youtube-footer':social.logo}}"
                  />
                </svg>
              </button>
            </div>
          </div>
        </li>
      </ul>

      <ul class="box u-margin-top-1 u-margin-right-20x">
        <div class="text-vertical">CONTACTOS</div>
        <ng-container *ngFor="let item of dataBranch">
          <li class="link_name title-address">{{ item.city }}</li>
          <li>
            <div class="text-address">
              <svg class="svg-medium">
                <use href="assets/svg/icons.svg#svg-pin" />
              </svg>
              <p class="address-branch">Dirección: {{ item.direction }}</p>
            </div>

            <div class="text-address">
              <svg>
                <use href="assets/svg/icons.svg#svg-telephone" />
              </svg>
              <p class="address-branch">Telf: {{ item.phone }}</p>
            </div>

            <div class="text-address">
              <svg>
                <use href="assets/svg/icons.svg#svg-mail" />
              </svg>
              <p class="address-branch">Correo electrónico: {{ item.email }}</p>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="logo-copyright">
      <div class="u-flex-center icon-logo">
        <svg for="nav-check">
          <use href="assets/svg/icons.svg#svg-logo-white" />
        </svg>
      </div>

      <p class="copyright_text u-align-center u-margin-0">
        (c) Derechos reservados {{currentYear}}.
      </p>
    </div>
  </div>
  <div class="bottom-details"></div>
</footer>
