import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ServeMessage } from '../../classes/ServeMessage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../restricted-services/login.service';
import { map } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _loginService: LoginService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let isToken = false;
    let TOKEN = '';
    if (this._loginService.getUserTokken()) {
      TOKEN = this._loginService.getUserTokken();
      isToken = true;
    }
    if (isToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${TOKEN}`,
        },
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          const handler = new ServeMessage(event, request);
          handler.showMessage(this._snackBar, this._router);
        }
        return event;
      })
    );
  }
}
