import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgModule,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionModal,
  IConfigModalResponsive,
  ModalResponsiveService,
  UpTempModal,
} from './modal-responsive.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'mono-repo-is-modal-responsive',
  templateUrl: './modal-responsive.component.html',
  styleUrls: ['./modal-responsive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalResponsiveComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  ELM: HTMLElement;
  modalContainer: any;
  modalContainerHeight: any;
  modalHeader: any;
  modalBody: any;
  modalBodyHeight: any;
  modalConfirm: any;

  config: IConfigModalResponsive;
  heightHeader = 0;
  heightFooter = 0;
  unsubscribe$ = new Subject();
  actionModal = ActionModal;

  constructor(
    private _elm: ElementRef,
    private _renderer: Renderer2,
    private _modalService: ModalResponsiveService,
    private _cdr: ChangeDetectorRef
  ) {
    this.ELM = this._elm.nativeElement;
  }

  ngOnInit() {
    this.modalHeader = this.ELM.querySelector('.modal_responsive__header');
    // --> Add 1 because has a border
    this.heightHeader = this.modalHeader.scrollHeight + 1;
    this.heightFooter =
      this.ELM.querySelector('.modal_responsive__footer').scrollHeight + 1;

    this.modalContainer = this.ELM.querySelector('.modal_responsive');
    this.addClass();
    this._renderer.addClass(
      this.modalContainer,
      `modal_responsive--${this.config.sizeModal}`
    );
    this._renderer.addClass(
      this.modalHeader,
      this.config.titleModal === 'hidden' ? 'hide-head-modal' : 'header-modal'
    );

    this.modalConfirm = this.ELM.querySelector('.modal_responsive__confirm');
    this.streamActionModal();
    this.streamUpdateTemplateModal();
  }

  ngAfterViewInit() {
    this.modalBody = this.ELM.querySelector('.modal_responsive__body');
    this.modalBodyHeight = this.modalBody.scrollHeight;
    this.modalContainerHeight =
      this.heightHeader + this.modalBodyHeight + this.heightFooter + 25;
    this.sizeModal();
  }

  private addClass(): void {
    if (this.config.className) {
      this._renderer.addClass(this.modalContainer, this.config.className);
    }
  }
  /***
   * get size of modal only one size
   */
  private sizeModal(): void {
    const heightScreen = window.innerHeight;
    if (heightScreen < this.modalContainerHeight) {
      this._renderer.setStyle(this.modalContainer, 'height', `90%`);
      this._renderer.addClass(
        this.modalBody,
        'modal_responsive__body--height-percent'
      );
    } else {
      this._renderer.setStyle(
        this.modalContainer,
        'height',
        `${this.modalContainerHeight}px`
      );
      this._renderer.removeClass(
        this.modalBody,
        'modal_responsive__body--height-percent'
      );
    }
  }

  /**
   * stream actions on modal
   */
  private streamActionModal(): void {
    this._modalService.actionModal$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((action) => {
        switch (action) {
          case ActionModal.OPEN_MODAL:
            this.showModalAnimation();
            break;

          case ActionModal.CLOSE_MODAL:
            this.hideModalContainerAndBgModal();
            break;

          case ActionModal.OPEN_MODAL_CONFIRMATION:
            this.showModalConfirmAndHideModalContainer();
            break;

          case ActionModal.CLOSE_ALL:
            this.hideModalConfirmAndHideBgModal();
            break;

          case ActionModal.RETURN_MODAL:
            this.hideModalConfirmAndShowModalContainer();
            break;
        }
      });
  }

  /**
   * Stream action to update template in modal
   */
  private streamUpdateTemplateModal(): void {
    this._modalService.updateTemplate$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        switch (response.action) {
          case UpTempModal.FOOTER:
            this.config.templateFooter = response.template;
            break;

          case UpTempModal.MODAL_CONFIRM:
            this.config.templateConfirmModal = response.template;
            break;
        }
        this._cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * SHOW modal animating bg and container modal
   */
  private showModalAnimation(): void {
    this._renderer.setStyle(this.ELM, 'transform', 'scale(1, 1)');
    setTimeout(() => {
      this._renderer.setStyle(this.ELM, 'opacity', '1');
      this.showModalContainer();
    }, 200);
  }
  /**
   * HIDE modal animating bg and container modal
   */
  private hideModalContainerAndBgModal(): void {
    this._renderer.removeClass(this.modalContainer, 'modal_responsive--show');
    setTimeout(() => {
      this.hideBgModal();
      // --> duration animation top = 300 (.modal_responsive)
    }, 300);
  }

  /**
   * SHOW modal confirm and hide modal container
   */
  private showModalConfirmAndHideModalContainer(): void {
    this.hideModalContainer();
    this.showModalConfirm();
  }
  /**
   * HIDE modal confirm and show modal container
   */
  private hideModalConfirmAndShowModalContainer(): void {
    this.hideModalConfirm();
    this.showModalContainer();
  }

  /**
   * HIDE modal confirm and bg modal
   */
  private hideModalConfirmAndHideBgModal(): void {
    this.hideModalConfirm();
    setTimeout(() => {
      this.hideBgModal();
      // --> duration animation opacity = 300 (.modal_responsive__confirm)
    }, 300);
  }

  /**
   * HIDE ONLY bg modal
   */
  private hideBgModal(): void {
    this._renderer.removeStyle(this.ELM, 'opacity');
    setTimeout(() => {
      this._renderer.removeStyle(this.ELM, 'transform');
      // --> duration animation opacity = 300 (:host)
    }, 300);
  }

  /**
   * SHOW ONLY modal container
   */
  private showModalContainer(): void {
    setTimeout(() => {
      this._renderer.addClass(this.modalContainer, 'modal_responsive--show');
    }, 170);
  }

  /**
   * HIDE ONLY modal container
   */
  private hideModalContainer(): void {
    this._renderer.removeClass(this.modalContainer, 'modal_responsive--show');
  }

  /**
   * SHOW ONLY modal confirm
   */
  private showModalConfirm(): void {
    this._renderer.setStyle(this.modalConfirm, 'top', '15px');
  }

  /**
   * HIDE ONLY modal confirm
   */
  private hideModalConfirm(): void {
    this._renderer.removeStyle(this.modalConfirm, 'top');
  }

  /**
   * Close modal from button x
   */
  closeModal(): void {
    this.hideModalContainerAndBgModal();
  }
}

@NgModule({
  declarations: [ModalResponsiveComponent],
  imports: [CommonModule, MatRippleModule],
  exports: [ModalResponsiveComponent],
  providers: [ModalResponsiveService],
})
export class ModalResponsiveModule {}
