<ng-container>
  <ngb-carousel
    *ngIf="dataCarrucel.length !== 0"
    [showNavigationArrows]="false"
    [showNavigationIndicators]="true"
    [interval]="timeInterval"
    class="carousel-home"
  >
    <ng-template ngbSlide *ngFor="let item of dataCarrucel">
      <div
        class="picsum-img-wrapper-banner u-cursor-pointer"
        (click)="redirectTo(item)"
      >
        <img
          default="'assets/images/default.png'"
          [src]="item.urlImage"
          class="image-banner"
          alt="carrucel-home"
        />
      </div>
      <div
        class="carousel-caption description-carrucel-home u-cursor-pointer"
        (click)="redirectTo(item)"
        *ngIf="item.title && item.description"
      >
        <h3 style="font-weight: bold; margin-bottom: 1rem">
          {{ item.title }}
        </h3>
        <p style="line-height: 21px">
          {{ item.description }}
        </p>
      </div>
    </ng-template>
  </ngb-carousel>
</ng-container>
