import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ICardsOptions } from 'src/app/interfaces/ICardsOptions.interfaces';

@Component({
  selector: 'app-card-options',
  templateUrl: './card-options.component.html',
})
export class CardOptionsComponent implements OnInit {
  @Input() dataCards: Array<any> = [];
  @Input() col: number = 3;
  @Input() button: boolean = false;
  @Input() class: string = '';
  @Input() truncate: boolean = false;
  @Input() titlePosition: string = 'u-text-center';
  @Input() config: any = {
    keyTitle: '',
    keyDescription: '',
    keyIcon: '',
  };
  @Output() onClick = new EventEmitter<ICardsOptions>();
  @Output() onClickContent = new EventEmitter<ICardsOptions>();

  constructor() {}

  ngOnInit(): void {}
}
