import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-card-options',
  templateUrl: './skeleton-card-options.component.html',
  styleUrls: ['./skeleton-card-options.component.scss']
})
export class SkeletonCardOptionsComponent implements OnInit {
  @Input() col: string='3';
  @Input() button: boolean =false;
  @Input() arrayNumber: number =6;

  constructor() { }

  ngOnInit(): void {
  }
  counter(i: number) {
    return new Array(i);
}
}
