import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-skeleton-carrucel-card',
  templateUrl: './skeleton-carrucel-card.component.html',
  styleUrls: ['./skeleton-carrucel-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkeletonCarrucelCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
