<ng-container *ngIf="dataCards && col">
  <div
    class="{{
      col === 3 && dataCards.length >= 3
        ? 'grid-col-3'
        : col === 2 && dataCards.length >= 3
        ? 'grids-col-2'
        : dataCards.length === 2
        ? 'grids-col-2'
        : dataCards.length === 1
        ? 'grids-col-1'
        : ''
    }}"
  >
    <div
      class="container-cards {{ class }}"
      matRipple
      [matRippleColor]="'#272d440a'"
      [ngClass]="{ 'u-cursor-pointer': !button }"
      [matRippleDisabled]="button"
      (click)="onClickContent.emit(item)"
      *ngFor="let item of dataCards"
    >
      <svg>
        <use
          attr.xlink:href="assets/svg/icons.svg#{{ item[config.keyIcon] }}"
        />
      </svg>
      <span class="{{ titlePosition }}">{{ item[config.keyTitle] }}</span>
      <p
        *ngIf="item[config.keyDescription]"
        class="{{ class }}"
        [ngClass]="{ 'text-three-line': truncate }"
      >
        {{ item[config.keyDescription] }}
      </p>
      <div class="container-cards_button button-options" *ngIf="button">
        <button
          matRipple
          [matRippleColor]="'#ffffff38'"
          (click)="onClick.emit(item)"
        >
          Leer Más
        </button>
      </div>
    </div>
  </div>
</ng-container>
