import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  /**
  * @FormatDatePipe Clase para formatear las fechas
  */
  transform(value: any, type?: string): any {
    if (type === 'only-date') {
      return  moment(value).format('DD-MM-YYYY');
    }

    if (value){
      const DATA = moment(value).format('DD-MM-YYYY');
      if (DATA === 'Invalid date') {
        return value;
      }
      return DATA;
    }
  }
}
