import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit {
  public searchGeneral$ = new Subject<boolean>();
  constructor(
    readonly router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {}

  onActivate() {
    if (this.router.url.indexOf('/public/search') >= 0) {
      this.searchGeneral$.next(false);
    } else {
      this.searchGeneral$.next(true);
    }
  }
}
