import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-calendary',
  templateUrl: './skeleton-calendary.component.html',
  styleUrls: ['./skeleton-calendary.component.scss']
})
export class SkeletonCalendaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
