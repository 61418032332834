import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-memories-institutionals',
  templateUrl: './skeleton-memories-institutionals.component.html',
  styleUrls: ['./skeleton-memories-institutionals.component.scss']
})
export class SkeletonMemoriesInstitutionalsComponent implements OnInit {

  responsiveOptions = [
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '968px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '660px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
