<div
  class="notification-item fadeIn"
  *ngFor="let item of [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]"
>
  <div class="notification-item_icon">
    <div class="skeleton-animation" style="width: 50px; height: 50px"></div>
  </div>
  <div class="description" style="width: calc(100% - 100px)">
    <p
      class="skeleton-animation"
      style="height: 10px; width: 95%; margin-top: 0px"
    ></p>
    <p
      class="skeleton-animation"
      style="height: 10px; width: 90%; margin-top: 7px"
    ></p>
    <p
      class="skeleton-animation"
      style="height: 10px; width: 60%; margin-top: 7px"
    ></p>
  </div>
</div>
