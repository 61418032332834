import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-card-destacado',
  templateUrl: './skeleton-card-destacado.component.html',
  styleUrls: ['./skeleton-card-destacado.component.scss']
})
export class SkeletonCardDestacadoComponent implements OnInit {

  @Input() type:string;
  constructor() { }

  ngOnInit(): void {
  }

}
