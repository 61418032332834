<div
  class="notification-item"
  matRipple
  [matRippleColor]="'#ffffff1f'"
  *ngFor="let item of [].constructor(rows)"
>
  <div class="notification-item_time">
    <div class="skeleton-animation" style="height: 25px; width: 100px"></div>
  </div>
  <div style="width: calc(100% - 200px); margin-left: 1rem">
    <p
      class="skeleton-animation"
      style="height: 10px; width: 90%; margin-top: 0px; margin-bottom: 5px"
    ></p>
    <p
      class="skeleton-animation"
      style="height: 10px; width: 90%; margin-top: 7px; margin-bottom: 5px"
    ></p>
    <p
      class="skeleton-animation"
      style="height: 10px; width: 60%; margin-top: 7px; margin-bottom: 5px"
    ></p>
  </div>

  <div class="notification-item_type">
    <div class="skeleton-animation" style="height: 25px; width: 100px"></div>
  </div>
</div>
