import { DOCUMENT, ViewportScroller } from '@angular/common';
import {
  Component,
  ComponentFactoryResolver,
  Inject,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subject } from 'rxjs';
import {
  ActionModal,
  IConfigModal,
  IConfigModalResponsive,
  ModalResponsiveService,
  SizeModal,
} from './components/modal-responsive/modal-responsive.service';
import { takeUntil } from 'rxjs/operators';
import { ModalResponsiveComponent } from './components/modal-responsive/modal-responsive.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'asoban-web';
  private unsubscribe$ = new Subject();
  private instanceRef: any;
  constructor(
    readonly viewportScroller: ViewportScroller,
    @Inject(DOCUMENT) private document: Document,
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef,
    private _modalService: ModalResponsiveService
  ) {}

  ngOnInit() {
    this.translateService.setDefaultLang('en');
    this.streamActionsModal();
    // window.addEventListener('scroll', function(){
    //    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Stream to create or remove modal from DOM woth actions of modal
   */
  private streamActionsModal(): void {
    this._modalService.refModal$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: IConfigModal) => {
        if (response.showModal) {
          // --> Open modal with time 70ms for animation
          this.openModalComponent(response.config);
          setTimeout(() => {
            this._modalService.actionModal$.next(ActionModal.OPEN_MODAL);
          }, 70);
        } else {
          this._modalService.actionModal$.next(ActionModal.CLOSE_MODAL);
          // --> close modal to end 2 animations both of 300ms
          setTimeout(() => {
            this.closeModalComponent();
          }, 700);
        }
      });
  }
  /**
   * Open modal responsive
   */
  private openModalComponent(config: IConfigModalResponsive): void {
    const compFactory = this._componentFactoryResolver.resolveComponentFactory(
      ModalResponsiveComponent
    );
    const viewContainerRef = this._viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(compFactory);
    const configModal: IConfigModalResponsive = {
      titleModal: config.titleModal || '',
      templateBody: config.templateBody || null,
      templateConfirmModal: config.templateConfirmModal || undefined,
      templateFooter: config.templateFooter || undefined,
      sizeModal: config.sizeModal || SizeModal.SMALL,
      className: config.className || '',
    };
    this.instanceRef = <ModalResponsiveComponent>componentRef.instance;
    this.instanceRef.config = configModal;
  }

  /**
   * Close and remove component
   */
  private closeModalComponent() {
    this._componentFactoryResolver.resolveComponentFactory(
      ModalResponsiveComponent
    );
    this._viewContainerRef.clear();
  }

  /**
   * trasnlate text primeng
   * @param lang
   */
  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }
  /**
   * active route oulet scroll top
   */
  onActivate() {
    window.scroll(0, 0);
  }
}
