import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum ActionModal {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_MODAL_CONFIRMATION,
  CLOSE_ALL,
  RETURN_MODAL,
}

/**
 * Update template modal actions
 */
export enum UpTempModal {
  FOOTER,
  MODAL_CONFIRM,
}
export enum SizeModal {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface IConfigModalResponsive {
  titleModal: string;
  templateFooter?: TemplateRef<any>;
  templateBody: TemplateRef<any>;
  templateConfirmModal?: TemplateRef<any>;
  sizeModal?: SizeModal;
  className?: string;
}

export interface FnActionModal {
  /***
   * Validate form and open modal confirmation
   */
  validateForm(): void;
  /**
   * Close modal
   */
  closeModal(): void;
  /**
   * Accept in modal confirmation
   */
  acceptModal(): void;
  /**
   * Return modal container
   */
  returnToModal(): void;
}

export interface IConfigModal {
  showModal: boolean;
  config?: IConfigModalResponsive;
}

export interface IUpdateModal {
  action: UpTempModal;
  template: TemplateRef<any>;
}

/**
 * Provider of service modal responsive
 */
@Injectable()
export class ModalResponsiveService {
  actionModal$ = new Subject<ActionModal>();
  /**
   * @To create or remove component from Html
   */
  refModal$ = new Subject<IConfigModal>();
  /**
   * @To add or replace templates modal
   */
  updateTemplate$ = new Subject<IUpdateModal>();
  /**
   * Resfresh data, is for stream when teminate action with the server, close al modal and sen value for update data
   */
  private refreshAll = new BehaviorSubject<any>(null);
  public refreshAll$ = this.refreshAll.asObservable();
  /**
   * Clean modal and stream data update
   */
  refreshModal(data?: any): void {
    this.actionModal$.next(ActionModal.CLOSE_ALL);
    this.refModal$.next({ showModal: false });
    this.refreshAll.next(data);
  }
}
