import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/restricted-services/login.service';
import { VarRouterPage } from '../setting/varRouterPage';

@Injectable({
  providedIn: 'root'
})
export class ValidateAccountGuard implements CanActivate {
  constructor(
    private _authentication:LoginService,
    private _router:Router
    ){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this._authentication.isVerifyUser() && !this._authentication.isLoginUser() ){
        return true;
      }else{
        return false;
      }
  }
  
}
