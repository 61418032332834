export class VarApi {
  // Developer Enviroment
  //static URL_BASE = `http://ec2-3-239-245-176.compute-1.amazonaws.com:9020/api`;

  // Certification Enviroment
  //static URL_BASE = `http://ec2-3-237-91-119.compute-1.amazonaws.com:8080/web-api/api`;

  // Production Enviroment
  //static URL_BASE = `https://ec2-3-83-81-4.compute-1.amazonaws.com/web-api/api`;
  //static URL_BASE = `https://18.233.76.128/web-api/api`;

  // static URL_API = `${VarApi.URL_BASE}/web`;
  // static URL_RESTRICTED = `${VarApi.URL_BASE}/restricted/web`;

  static URL_API = 'web';
  static URL_RESTRICTED = 'restricted/web';

  static URL_SOCIAL_MEDIA = `${VarApi.URL_API}/social-media`;
  static URL_CATEGORIES = `${VarApi.URL_API}/categories`;
  static URL_SECTIONS = `${VarApi.URL_API}/sections`;
  static URL_HOME = `${VarApi.URL_API}/home`;
  static URL_POPUP = `${VarApi.URL_API}/popup`;
  static URL_SUBSECTIONS = `${VarApi.URL_API}/subsections`;
  static URL_INSTITUCIONAL = `${VarApi.URL_API}/institutional`;
  static URL_CONTACT = `${VarApi.URL_API}/contact`;
  static URL_OFFICES = `${VarApi.URL_API}/offices`;
  static URL_EVENTS = `${VarApi.URL_API}/events`;
  static URL_GENERIC = `${VarApi.URL_API}`;
  static URL_INITIATIVES = `${VarApi.URL_API}/initiatives`;
  static URL_DOMAIN_VALUE = `${VarApi.URL_API}/domain-value`;
  static URL_SEARCH = `${VarApi.URL_API}/search`;
  static URL_NEWS = `${VarApi.URL_API}/news`;
  static URL_STATUTES = `${VarApi.URL_API}/statutes`;
  static URL_FAQ = `${VarApi.URL_API}/frequent_questions`;

  static URL_BRANCHES_ATM = `${VarApi.URL_RESTRICTED}/branches-atms`;
  static URL_HOME_RESTRICTED = `${VarApi.URL_RESTRICTED}/home`;
  static URL_NORMATIVE = `${VarApi.URL_RESTRICTED}/normative`;
  static URL_COMMISSIONS = `${VarApi.URL_RESTRICTED}/commissions`;
  static URL_SUSCRIPTION = `${VarApi.URL_RESTRICTED}/suscription`;
  static URL_NOTIFICATION = `${VarApi.URL_RESTRICTED}/notification`;
  static URL_VALUES = `${VarApi.URL_RESTRICTED}/values`;
  static URL_RESTRICTED_GENERIC = `${VarApi.URL_RESTRICTED}/generic`;
  static URL_CALENDAR = `${VarApi.URL_RESTRICTED}/calendar`;
  static URL_SEARCH_RESTRICTED = `${VarApi.URL_API}/search/restricted`;
  static URL__VALUES_COMMISSIONS = `${VarApi.URL_RESTRICTED}/values/commissions`;
  static URL__RESTRICTED_EVENT = `${VarApi.URL_RESTRICTED}/event`;

  //register and login
  static URL_LOGIN = `${VarApi.URL_API}/signin`;
  static URL_RESEND_CODE = `${VarApi.URL_API}/resend-code`;
  static URL_VERIFY = `${VarApi.URL_API}/verify`;
  static URL_VERIFY_SIGNUP = `${VarApi.URL_API}/verify-signup`;
  static URL_USERS = `${VarApi.URL_API}/signup`;
  static URL_USER = `${VarApi.URL_API}/users`;
  static URL_CONTENT_PDF = `${VarApi.URL_API}/content-pdf`;
  static URL_CONTENT = `${VarApi.URL_RESTRICTED}/generic/content`;
  static URL_CONTENT_MANAGER = `${VarApi.URL_API}/content-management`;

  static IP_ADDRESS = 'https://geolocation-db.com/json/';
}
