import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IButton } from 'src/app/interfaces/button-paint';
import { Location } from '@angular/common';
import { LocalStorageStg } from 'src/app/setting/localStorageStg';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() paintButton: IButton;
  @Input() type: string;
  @Input() typeButton: string = 'button';
  @Input() isDisabled: boolean;
  @Output() clickBtn = new EventEmitter<void>();

  constructor(public location: Location) {}

  ngOnInit(): void {}

  /**
   * go back
   */
  toBack() {
    setTimeout(() => {
      this.location.back();
      const contentContainer =
        document.querySelector('.container-public') || window;
      contentContainer.scrollTo(0, 0);
      localStorage.removeItem(LocalStorageStg.HOME_PUBLICATIONS);
      localStorage.removeItem(LocalStorageStg.HOME_PRESS);
    }, 100);
  }
}
