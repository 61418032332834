import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IContactAddDto } from 'src/app/interfaces/api/IContact.interface';
import { IOffice } from 'src/app/interfaces/api/IOffice.interface';
import { VarApi } from 'src/app/setting/varApi';
import { ApiService } from '../../interceptors/api.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private _api: ApiService) {}

  /**
   * This function takes a data object of type IContactAddDto and returns an Observable of type
   * IContactAddDto.
   * @param {IContactAddDto} data - IContactAddDto
   * @returns IContactAddDto
   */
  registerContact(data: IContactAddDto): Observable<IContactAddDto> {
    return this._api.postDataValues(`${VarApi.URL_CONTACT}`, data);
  }

  /**
   * It returns an Observable of type IOffice.
   * @returns Observable;IOffice;
   */
  getOffices(): Observable<IOffice> {
    return this._api.getDataValues(`${VarApi.URL_OFFICES}`);
  }
}
