import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HandleRouteApiService } from '../handle-route-api.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private _http: HttpClient,
    private _handRouteApi: HandleRouteApiService
  ) {}

  /**
   * The route service is a service that returns a route based on the url.
   * @param {string} url - string - the url of the route you want to get the data from
   * @returns;
   */
  getDataValues(url: string, options?: any): Observable<any> {
    return this._handRouteApi.getRoute(url).pipe(
      switchMap((route) => {
        return this._http.get(`${route}`, options);
      })
    );
  }

  /**
   * It takes a url and data as parameters, then it calls the getRoute function from the
   * HandRouteApiService, which returns an observable. Then it uses the switchMap operator to switch to a
   * new observable, which is the result of the http post request.
   * @param {string} url - string - the url of the route you want to call
   * @param {any} data - any - this is the data that you want to send to the API
   * @returns The return value is an Observable.
   */
  postDataValues(url: string, data: any): Observable<any> {
    return this._handRouteApi.getRoute(url).pipe(
      switchMap((route) => {
        return this._http.post(`${route}`, data);
      })
    );
  }

  /**
   * The route service is a service that I created to get the route from the server.
   * @param {string} url - string - the url of the route you want to hit
   * @param {any} data - any - this is the data that you want to send to the server.
   * @returns The Observable&lt;any&gt; is being returned.
   */
  putDataValues(url: string, data: any): Observable<any> {
    return this._handRouteApi.getRoute(url).pipe(
      switchMap((route) => {
        return this._http.put(`${route}`, data);
      })
    );
  }

  deleteDataValues(url: string): Observable<any> {
    return this._handRouteApi.getRoute(url).pipe(
      switchMap((route) => {
        return this._http.delete(`${route}`);
      })
    );
  }
}
