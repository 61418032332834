import { Component, OnInit } from '@angular/core';
import { ICategories } from 'src/app/interfaces/components/ICategories.interfaces';
import { IOficce } from 'src/app/interfaces/components/IOffice.interface';
import { ISocialMedia } from 'src/app/interfaces/components/ISocialMedia.interfaces';
import { ContactService } from 'src/app/services/public-services/contact/contact.service';
import { HomeService } from 'src/app/services/public-services/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  dataNav: Array<ICategories> = [];
  dataBranch: Array<IOficce> = [];
  dataSocial: Array<ISocialMedia> = [];
  currentYear: number;
  constructor(
    private _homeService: HomeService,
    private _contactService: ContactService
  ) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.dataNavigation();
    this.getDataBranch();
    this.dataSocialMedia();
  }

  /**
   * get data social media
   */
  dataSocialMedia() {
    this._homeService.getSocialMedia().subscribe((response) => {
      this.dataSocial = response;
    });
  }

  /**
   * get data office
   */
  getDataBranch() {
    this._contactService.getOffices().subscribe((response) => {
      this.dataBranch = response['office'];
    });
  }

  /**
   * It's a function that calls a service that returns an array of objects.
   */
  dataNavigation() {
    this._homeService
      .getNavigationCategories()
      .subscribe((response: Array<ICategories>) => {
        this.dataNav = response;
      });
  }

  /**
   * redirect to social media
   * @param url
   */
  redirectSocialMedia(url: string) {
    window.open(url, '_blank');
  }
}
