import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  constructor(private _snackBar: MatSnackBar) { }

  openSnackBar(message: string, action: string, nameclass: string) {
    this._snackBar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [nameclass],
      duration: 5 * 1000,
    });
  }
}
