import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';

// --> private _handRouteApi: HandleRouteApiService
@Injectable({
  providedIn: 'root'
})
export class HandleRouteApiService {

  private readonly URL: string;

  constructor() {
    this.URL = environment.apiRoute;
  }

  /**
   * Is async because in the time read on localStorage or other service
   * @param name
   */
  getRoute(name: string): Observable<string> {
    return of(`${this.URL}/${name}`);
  }
}
