import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SideCalendarModule } from 'src/app/components/side-calendar/side-calendar.module';
import { AuthGuard } from 'src/app/security/auth.guard';
import { LoginGuard } from 'src/app/security/login-guard.guard';
import { ValidateAccountGuard } from 'src/app/security/validate-account.guard';
import { ValidateMobileGuard } from 'src/app/security/validate-mobile.guard';
import { ValidateSignupGuard } from 'src/app/security/validate-signup.guard';
import { RestrictionSectionComponent } from './restriction-section.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'prefix',
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'validate-account',
    canActivate: [ValidateAccountGuard],
    loadChildren: () =>
      import('./validate-account/validate-account.module').then(
        (m) => m.ValidateAccountModule
      ),
  },
  {
    path: 'validate-signup',
    canActivate: [ValidateSignupGuard],
    loadChildren: () =>
      import('./validate-sigup/validate-sigup.module').then(
        (m) => m.ValidateSignupModule
      ),
  },
  {
    path: 'validate-mobile',
    canActivate: [ValidateMobileGuard],
    loadChildren: () =>
      import('./validate-mobile/validate-mobile.module').then(
        (m) => m.ValidateMobileModule
      ),
  },
  {
    path: 'change-password/:token',
    loadChildren: () =>
      import('./change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: 'recover-password/:token',
    loadChildren: () =>
      import('./forget-password-change/forget-password-change.module').then(
        (m) => m.ForgetPasswordChangeModule
      ),
  },
  {
    path: 'first-login',
    loadChildren: () =>
      import('./change-password-login/change-password-login.module').then(
        (m) => m.ChangePasswordLoginModule
      ),
  },
  {
    path: '',
    component: RestrictionSectionComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'home',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'exclusive-content',
        loadChildren: () =>
          import('./exclusive-content/exclusive-content.module').then(
            (m) => m.ExclusiveContentModule
          ),
      },
      {
        path: 'atm-branch',
        loadChildren: () =>
          import('./atm-branch/atm-branch.module').then(
            (m) => m.AtmBranchModule
          ),
      },
      {
        path: 'my-groups',
        loadChildren: () =>
          import('./my-groups/my-groups.module').then((m) => m.MyGroupsModule),
      },
      {
        path: 'my-tools',
        loadChildren: () =>
          import('./my-tools/my-tools.module').then((m) => m.MyToolsModule),
      },
      {
        path: 'digital-signature',
        loadChildren: () =>
          import('./digital-signature/digital-signature.module').then(
            (m) => m.DigitalSignatureModule
          ),
      },
      {
        path: 'profile-user',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
    ],
  },
];

@NgModule({
  declarations: [RestrictionSectionComponent],
  imports: [RouterModule.forChild(routes), SideCalendarModule],
  exports: [RouterModule],
})
export class RestrictionSectionRoutingModule {}
