export class VarRouterPage {
  static RESTRICTED = '/restricted';
  static PUBLIC = '/public';
  // public section
  static HOME = `${VarRouterPage.PUBLIC}/home`;
  static INSTITUTIONAL = `${VarRouterPage.PUBLIC}/institucional`;
  static PUBLICATIONS = `${VarRouterPage.PUBLIC}/publications/content`;
  static PRESS_RELEASE = `${VarRouterPage.PUBLIC}/press-release`;
  static EVENTS = `${VarRouterPage.PUBLIC}/events`;
  static INITIATIVES = `${VarRouterPage.PUBLIC}/initiatives`;
  static SEARCH = `${VarRouterPage.PUBLIC}/search`;
  static EVENT_REGISTER = `${VarRouterPage.PUBLIC}/event-registration`;

  static PUBLICATIONS_SECTIONS = `${VarRouterPage.PUBLICATIONS}/sections`;
  static DETAIL_PUBLICATIONS = `${VarRouterPage.PUBLICATIONS}/sections/detail`;
  static DETAIL_PRESS_RELEASE = `${VarRouterPage.PRESS_RELEASE}/sections/detail`;
  static SECTION_PRESS_RELEASE = `${VarRouterPage.PRESS_RELEASE}/content/sections`;

  static DETAIL_EVENTS = `${VarRouterPage.EVENTS}/detail`;
  static DETAIL_INITIATIVES = `${VarRouterPage.INITIATIVES}/detail`;
  static SEARCH_EVENTS = `${VarRouterPage.EVENTS}/search-event`;
  static SEARCH_PRESS_RELEASE = `${VarRouterPage.PRESS_RELEASE}/sections/search-press`;
  static SEARCH_PUBLICATIONS = `${VarRouterPage.PUBLICATIONS}/sections/search-publications`;

  static REPRESENTATIONS = `${VarRouterPage.INSTITUTIONAL}/section/organization/representations`;
  static CHART = `${VarRouterPage.INSTITUTIONAL}/section/organization/organization-structure`;
  static INTITUTIONAL_MEMORIES = `${VarRouterPage.INSTITUTIONAL}/section/institutional-memories`;

  // public restricted
  static EXCLUSIVE_CONTENT = `${VarRouterPage.RESTRICTED}/exclusive-content`;
  static MY_GROUP = `${VarRouterPage.RESTRICTED}/my-groups`;
  static ATM = `${VarRouterPage.RESTRICTED}/atm-branch`;
  static MY_TOOLS = `${VarRouterPage.RESTRICTED}/my-tools`;
  static DIGITAL_SIGNATURE = `${VarRouterPage.RESTRICTED}/digital-signature`;
  static FORGOT_PASSWORD = `${VarRouterPage.RESTRICTED}/forgot-password`;
  static REGISTER = `${VarRouterPage.RESTRICTED}/register`;
  static VALIDATE_MOBILE = `${VarRouterPage.RESTRICTED}/validate-mobile`;
  static VALIDATE_ACCOUNT = `${VarRouterPage.RESTRICTED}/validate-account`;
  static VALIDATE_SIGNUP = `${VarRouterPage.RESTRICTED}/validate-signup`;
  static HOME_RESTRICTED = `${VarRouterPage.RESTRICTED}/home`;
  static LOGIN_RESTRICTED = `${VarRouterPage.RESTRICTED}/login`;
  static VALIDATE_RESTRICTED = `${VarRouterPage.RESTRICTED}/validate-account`;
  static PROFILE_RESTRICTED = `${VarRouterPage.RESTRICTED}/profile-user`;

  static PRINT_CERTIFICATE = `${VarRouterPage.DIGITAL_SIGNATURE}/print-certificate`;
  static DIRECTORY = `${VarRouterPage.MY_GROUP}/directory`;

  static FIRTLOGIN = `${VarRouterPage.RESTRICTED}/first-login`;
}
