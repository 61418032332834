import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestrictionSectionComponent } from 'src/app/pages/restriction-section/restriction-section.component';
import { CalendarService } from 'src/app/services/restricted-services/calendar/calendar.service';
import { FilterGeneralService } from 'src/app/services/restricted-services/filter-general.service';
import { LoginService } from 'src/app/services/restricted-services/login.service';
import { LocalStorageStg } from 'src/app/setting/localStorageStg';
import { VarRouterPage } from 'src/app/setting/varRouterPage';
export const modalAnimation = trigger('modalAnimation', [
  state('void', style({ opacity: 0 })),
  state('*', style({ opacity: 1 })),
  transition('void => *', animate('300ms')),
  transition('* => void', animate('300ms')),
]);
@Component({
  selector: 'app-side-calendar',
  templateUrl: './side-calendar.component.html',
})
export class SideCalendarComponent implements OnInit {
  @Output() openSidebar = new EventEmitter<boolean>();
  private isOpen = true;
  public val = '';
  isShowingInputSearch = false;
  ELM: HTMLElement;
  modalRef: BsModalRef;
  configNgxBootstrap = {
    backdrop: true,
    keyboard: false,
    ignoreBackdropClick: true,
    animated: true,
  };
  showSearch: boolean;
  dateDay: string;
  dataEvent: Array<any> = [];
  User = JSON.parse(localStorage.getItem(LocalStorageStg.USER));
  constructor(
    private _router: Router,
    private _renderer: Renderer2,
    private _elm: ElementRef,
    private _bsModalService: BsModalService,
    private _LoginService: LoginService,
    private _restrictionSectionComponent: RestrictionSectionComponent,
    private _filterGeneralService: FilterGeneralService,
    private _calendarService: CalendarService
  ) {
    this.ELM = this._elm.nativeElement;
    this.showSearch =
      this._router.url.indexOf('/restricted/home') >= 0 ? true : false;
  }
  isShowLoader: boolean = true;
  isShowLoaderActivities: boolean = true;

  ngOnInit(): void {
    this._restrictionSectionComponent.searchRestricted$.subscribe(
      (response) => {
        this.showSearch = response;
      }
    );

    this.getDataEvent(new Date());
    setTimeout(() => {
      this.isShowLoader = false;
    }, 500);
  }

  getDataEvent(date: any) {
    this.isShowLoaderActivities = true;
    this.dateDay = moment(date).format('YYYY-MM-DD');
    this._calendarService.getEventCalendar(this.dateDay, 5).subscribe(
      (response) => {
        this.dataEvent = response;
        setTimeout(() => {
          this.isShowLoaderActivities = false;
          setTimeout(() => {
            this.animationsItems();
          }, 100);
        }, 500);
      },
      (error) => {
        this.isShowLoaderActivities = false;
      }
    );
  }

  showEvents(event) {
    this.getDataEvent(event);
  }

  ngAfterViewInit() {}

  /**
   * animation items events
   */
  animationsItems() {
    var items = document.getElementsByClassName('items-activities');
    if (items) {
      for (let i = 0; i < items.length; ++i) {
        setTimeout(() => {
          items[i].classList.add('in-right');
        }, i * 100);
      }
    }
  }

  /**
   * collapse calendar
   */
  actionSidebar(): void {
    this.isOpen = !this.isOpen;
    this.openSidebar.emit(this.isOpen);
  }

  /**
   * Abrir u ocultar el input buscador
   */
  openSearch(): void {
    const inputRef = this.ELM.querySelector('.form_search__input');
    this._renderer.addClass(inputRef, 'form_search__input--show');
    if (this.isShowingInputSearch) {
      this._renderer.removeClass(inputRef, 'form_search__input--show');
      this._renderer.addClass(inputRef, 'form_search__input--hidden');
    } else {
      this._renderer.addClass(inputRef, 'form_search__input--show');
      this._renderer.removeClass(inputRef, 'form_search__input--hidden');
    }
    this.isShowingInputSearch = !this.isShowingInputSearch;
  }

  public search() {
    this.openSearch();
    if (this.val != '') {
      this._filterGeneralService.searchValue$.next(this.val);
    }
    this.val = '';
  }

  /**
   * redirect user profile
   */
  profileUser() {
    this._router.navigate([VarRouterPage.PROFILE_RESTRICTED]);
  }

  /**
   *
   * @param template
   */
  logout(template) {
    this.openModalConfirmation(template);
  }

  /**
   * actions modal confirmation logout
   * @param type
   */
  actionsModalLogout(type: string) {
    switch (type) {
      case 'confirm':
        this._LoginService.logout();
        this.declineModal();
        break;
      case 'cancel':
        this.declineModal();
        break;
    }
  }

  returnWeb(template) {
    this.openModalConfirmation(template);
  }

  /**
   * actions modal return web
   * @param type
   */
  actionsModalReturn(type: string) {
    switch (type) {
      case 'confirm':
        this._router.navigate([VarRouterPage.HOME]);
        this.declineModal();
        break;
      case 'cancel':
        this.declineModal();
        break;
    }
  }

  /**
   * open modal confimation
   * @param template
   */
  openModalConfirmation(template: TemplateRef<any>) {
    this.modalRef = this._bsModalService.show(template, {
      class: 'modal-fade modal-sm modal-only-confirmation ',
      ...this.configNgxBootstrap,
    });
  }

  /**
   * close modal
   */
  declineModal() {
    this.modalRef.hide();
  }

  /**
   * go to url event
   * @param item
   */
  redirectUrlEvent(item) {
    if (item['url']) {
      window.open(item['url'], '_blank');
    }
  }
}
