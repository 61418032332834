<div class="nav" id="navbar-init">
  <input type="checkbox" id="nav-check" [(ngModel)]="checked" />
  <div class="nav-header">
    <div class="nav-title">
      <a routerLink="/public/home" class="u-cursor-pointer logo-home">
        <img width="160px" src="assets/images/logo.png" alt=""
      /></a>
    </div>
  </div>
  <div class="nav-btn">
    <label for="nav-check">
      <svg
        for="nav-check"
        style="width: 30px; height: 30px; margin-top: -5px; margin-left: -18px"
      >
        <use href="assets/svg/icons.svg#svg-menu-colp" />
      </svg>
    </label>
  </div>

  <div class="nav-login u-flex-center">
    <div class="nav-login_button" *ngIf="!isLogin">
      <button (click)="login()">LOGIN</button>
    </div>
    <ng-container *ngIf="dataSocial.length > 0">
      <div class="nav-login_share">
        <svg
          *ngFor="let social of dataSocial"
          (click)="redirectSocialMedia(social.url)"
        >
          <use attr.xlink:href="assets/svg/icons.svg#{{ social.logo }}" />
        </svg>
      </div>
    </ng-container>

    <div class="divider-section" *ngIf="isLogin && dataSocial.length > 0"></div>
    <ng-container *ngIf="nameUser">
      <div class="profile-button" (click)="redirectTorestricted()">
        <span>{{
          nameUser["name"].substring(0, 1) +
            "" +
            nameUser["lastName"].substring(0, 1)
        }}</span>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="dataNav.length > 0">
    <div class="nav-links">
      <a
        [routerLink]="item.urlWeb"
        (click)="redirectToPage(item)"
        routerLinkActive="active"
        *ngFor="let item of dataNav"
        >{{ item.title }}</a
      >
      <a>
        <ng-container *ngIf="showSearch">
          <form method="get" class="form_search" fxLayout="row">
            <input
              type="text"
              id="txt_buscar"
              name="buscadorPro"
              [(ngModel)]="val"
              placeholder="Buscar..."
              class="form_search__input"
              (keyup.enter)="search()"
            />
            <button
              (click)="openSearch()"
              type="button"
              class="form_search__button form_search__button--white"
              *ngIf="!isShowingInputSearch; else btnClose"
            >
              <svg class="search-button">
                <use href="assets/svg/icons.svg#svg-search" />
              </svg>
            </button>
            <ng-template #btnClose>
              <button
                (click)="search()"
                type="button"
                class="form_search__button form_search__button--blue"
              >
                <svg class="search-button">
                  <use href="assets/svg/icons.svg#svg-search" />
                </svg>
              </button>
            </ng-template>
          </form>
        </ng-container>
      </a>
    </div>
  </ng-container>
</div>
