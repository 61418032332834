import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../restricted-services/login.service';
import { AlertMessageService } from '../alert-message/alert-message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _snackBar: MatSnackBar,
    private _login: LoginService,
    private _alertMessageService: AlertMessageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 400:
            setTimeout(() => {
              this._alertMessageService.openSnackBar(
                err.error.apierror.message,
                'x',
                'error-toast'
              );
            }, 300);
            break;
          case 401:
            setTimeout(() => {
              if (err.error) {
                if ((err.error.error = 'Unauthorized')) {
                  this._alertMessageService.openSnackBar(
                    'Su sesión ha expirado',
                    'x',
                    'error-toast'
                  );
                  this._login.logout();
                } else {
                  this._alertMessageService.openSnackBar(
                    err.error.error,
                    'x',
                    'error-toast'
                  );
                }
              }
              this._login.logout();
            }, 300);
            break;
          case 403:
            this._alertMessageService.openSnackBar(
              err.error.apierror.message,
              'x',
              'error-toast'
            );
            break;
          case 404:
            if (err.error.error) {
              this._alertMessageService.openSnackBar(
                err.error.error,
                'x',
                'error-toast'
              );
            } else if (err.error.status) {
              this._alertMessageService.openSnackBar(
                err.error.status,
                'x',
                'error-toast'
              );
            } else {
              this._alertMessageService.openSnackBar(
                err.error.apierror.message,
                'x',
                'error-toast'
              );
            }
            break;
          case 500:
            if (err.error.message) {
              this._alertMessageService.openSnackBar(
                err.error.message,
                'x',
                'error-toast'
              );
            } else {
              this._alertMessageService.openSnackBar(
                'Error en el servidor, comuníquese con el administrador',
                'x',
                'error-toast'
              );
            }

            break;
          default:
            if (err.error && err.statusText !== 'Unknown Error') {
              this._alertMessageService.openSnackBar(
                'Error en el servidor, comuníquese con el administrador',
                'x',
                'error-toast'
              );
            }
        }

        if (err.statusText === 'Unknown Error') {
          this._alertMessageService.openSnackBar(
            'Servicios no disponibles por el momento',
            'x',
            'error-toast'
          );
        }

        const error = err.message || err.error.message;
        return throwError(error);
      })
    );
  }
}
