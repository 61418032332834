<h1 class="u-text-center modal_confirmation">{{ titleModal }}</h1>
<div class="u-text-center u-flex-center buttons-confirmation">
  <app-button
    [paintButton]="buttonCancel"
    (clickBtn)="onCancel.emit(actionModal.RETURN_MODAL)"
  >
  </app-button>
  <app-button
    [paintButton]="buttonAccept"
    (clickBtn)="onAccept.emit(actionModal.CLOSE_ALL)"
  >
  </app-button>
</div>
