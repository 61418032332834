<ngb-carousel
  class="carrucel-about-us"
  [showNavigationArrows]="false"
  [showNavigationIndicators]="false"
>
  <ng-template ngbSlide *ngFor="let item of [1]">
    <div class="picsum-img-wrapper-about-us u-cursor-pointer">
      <div class="container-descriptions">
        <h3 class="skeleton-animation" style="height: 18px; width: 40%"></h3>
        <p-scrollPanel
          class="panel-scroll"
          [style]="{ width: '100%', height: '220px' }"
        >
          <p
            class="skeleton-animation"
            style="height: 18px; width: 90% ;margin: 5px 0;"
          ></p>
          <p class="skeleton-animation" style="height: 18px; width: 90%;margin: 10px 0;"></p>
          <p class="skeleton-animation" style="height: 18px; width: 90%;margin: 10px 0;"></p>
          <p class="skeleton-animation" style="height: 18px; width: 90%;margin: 10px 0;"></p>
          <p class="skeleton-animation" style="height: 18px; width: 90%;margin: 10px 0;"></p>
          <p class="skeleton-animation" style="height: 18px; width: 90%;margin: 10px 0;"></p>

          <p-scrollTop
            target="parent"
            styleClass="custom-scrolltop"
            [threshold]="20"
            icon="pi pi-arrow-up"
          ></p-scrollTop>
        </p-scrollPanel>
      </div>
      <div class="skeleton-animation image"></div>
    </div>
  </ng-template>
</ngb-carousel>
