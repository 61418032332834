import { Component } from '@angular/core';

@Component({
  selector: 'innova-block-template',
  template: `
    <div class="container_block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 497.789 318"
        enable-background="new 0 0 497.789 318"
        xml:space="preserve"
      >
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      fill="var(--color-secondary-dark)"
                      d="M125.674,267.546h-8.32c-0.932,0-1.701-0.232-2.299-0.703c-0.602-0.466-1.037-1.045-1.303-1.739        l-4.32-11.803H85.465l-4.32,11.803c-0.213,0.614-0.635,1.175-1.262,1.678c-0.623,0.511-1.393,0.765-2.299,0.765h-8.361        l22.727-57.855h11.002L125.674,267.546z M88.227,245.696h18.445l-7.043-19.24c-0.32-0.854-0.672-1.862-1.057-3.023        c-0.391-1.159-0.768-2.419-1.143-3.778c-0.375,1.359-0.742,2.627-1.102,3.8c-0.359,1.176-0.713,2.201-1.063,3.08        L88.227,245.696z"
                      style="&#10;    fill:var(--color-secondary-dark);&#10;"
                    />
                    <path
                      fill="var(--color-secondary-dark)"
                      d="M170.268,220.213c-0.293,0.589-0.633,1.001-1.02,1.243c-0.387,0.236-0.846,0.358-1.381,0.358        c-0.533,0-1.133-0.208-1.801-0.62c-0.666-0.413-1.451-0.875-2.359-1.38c-0.91-0.507-1.967-0.969-3.18-1.382        c-1.217-0.414-2.648-0.621-4.303-0.621c-1.494,0-2.795,0.179-3.9,0.541c-1.105,0.358-2.041,0.861-2.801,1.502        c-0.76,0.637-1.328,1.407-1.697,2.301c-0.375,0.895-0.563,1.874-0.563,2.938c0,1.363,0.381,2.496,1.141,3.401        c0.76,0.909,1.764,1.679,3.021,2.319c1.252,0.643,2.68,1.217,4.279,1.722c1.6,0.509,3.234,1.049,4.902,1.62        c1.664,0.574,3.301,1.241,4.902,2.002c1.6,0.759,3.023,1.722,4.279,2.88c1.254,1.161,2.26,2.581,3.021,4.263        c0.758,1.678,1.141,3.72,1.141,6.121c0,2.613-0.449,5.06-1.342,7.341c-0.896,2.28-2.201,4.269-3.922,5.961        c-1.719,1.693-3.818,3.029-6.303,4.002c-2.479,0.972-5.32,1.458-8.52,1.458c-1.842,0-3.654-0.179-5.441-0.539        c-1.787-0.358-3.5-0.873-5.141-1.539c-1.641-0.667-3.174-1.468-4.602-2.401c-1.426-0.933-2.701-1.974-3.822-3.121l3.123-5.162        c0.291-0.371,0.646-0.685,1.059-0.939c0.414-0.254,0.873-0.38,1.383-0.38c0.664,0,1.385,0.274,2.158,0.822        c0.775,0.545,1.688,1.151,2.742,1.816c1.053,0.671,2.293,1.279,3.719,1.822c1.428,0.545,3.141,0.821,5.143,0.821        c3.066,0,5.441-0.728,7.119-2.183c1.684-1.454,2.523-3.542,2.523-6.26c0-1.52-0.383-2.759-1.141-3.72        c-0.76-0.96-1.768-1.769-3.021-2.423c-1.252-0.651-2.682-1.206-4.283-1.66c-1.598-0.451-3.225-0.945-4.879-1.481        c-1.652-0.531-3.281-1.17-4.883-1.919c-1.598-0.745-3.023-1.72-4.279-2.921c-1.254-1.199-2.26-2.701-3.02-4.502        c-0.762-1.798-1.141-4.019-1.141-6.659c0-2.106,0.42-4.163,1.26-6.161c0.842-2.001,2.066-3.774,3.682-5.322        c1.613-1.548,3.594-2.787,5.941-3.721c2.348-0.935,5.025-1.399,8.039-1.399c3.416,0,6.564,0.534,9.445,1.6        c2.879,1.065,5.334,2.56,7.357,4.479L170.268,220.213z"
                    />
                    <path
                      fill="var(--color-secondary-dark)"
                      d="M244.15,238.618c0,4.239-0.707,8.168-2.123,11.78c-1.412,3.617-3.4,6.743-5.961,9.383        c-2.559,2.643-5.641,4.701-9.242,6.185c-3.6,1.478-7.588,2.217-11.961,2.217c-4.375,0-8.369-0.739-11.984-2.217        c-3.615-1.483-6.707-3.542-9.281-6.185c-2.574-2.64-4.566-5.766-5.982-9.383c-1.412-3.612-2.117-7.541-2.117-11.78        c0-4.241,0.705-8.167,2.117-11.783c1.416-3.614,3.408-6.74,5.982-9.38c2.574-2.645,5.666-4.703,9.281-6.185        s7.609-2.219,11.984-2.219c4.373,0,8.361,0.745,11.961,2.24c3.602,1.494,6.684,3.553,9.242,6.18        c2.561,2.631,4.549,5.749,5.961,9.363C243.443,230.451,244.15,234.377,244.15,238.618z M233.105,238.618        c0-3.174-0.418-6.022-1.26-8.542c-0.84-2.522-2.047-4.663-3.619-6.422c-1.572-1.76-3.49-3.107-5.742-4.042        c-2.254-0.93-4.795-1.401-7.621-1.401c-2.828,0-5.377,0.472-7.643,1.401c-2.268,0.935-4.197,2.282-5.779,4.042        c-1.59,1.759-2.809,3.899-3.664,6.422c-0.854,2.52-1.277,5.368-1.277,8.542s0.424,6.021,1.277,8.542        c0.855,2.521,2.074,4.653,3.664,6.401c1.582,1.746,3.512,3.088,5.779,4.021c2.266,0.934,4.814,1.4,7.643,1.4        c2.826,0,5.367-0.467,7.621-1.4c2.252-0.933,4.17-2.274,5.742-4.021c1.572-1.748,2.779-3.881,3.619-6.401        C232.688,244.64,233.105,241.792,233.105,238.618z"
                    />
                    <path
                      fill="var(--color-secondary-dark)"
                      d="M259.338,267.546V209.69h19.963c3.791,0,7.021,0.36,9.701,1.081c2.684,0.721,4.883,1.746,6.604,3.082        c1.719,1.331,2.982,2.947,3.783,4.84c0.799,1.894,1.197,4.028,1.197,6.399c0,1.363-0.199,2.661-0.598,3.904        c-0.402,1.241-1.021,2.398-1.863,3.478c-0.84,1.08-1.906,2.063-3.199,2.943c-1.295,0.878-2.836,1.626-4.623,2.239        c7.951,1.788,11.922,6.079,11.922,12.883c0,2.455-0.465,4.722-1.398,6.802c-0.936,2.081-2.293,3.873-4.082,5.381        c-1.783,1.51-3.988,2.687-6.6,3.54c-2.615,0.854-5.602,1.283-8.963,1.283H259.338z M270.1,234.737h8.6        c3.656,0,6.43-0.666,8.324-2.002c1.895-1.331,2.84-3.452,2.84-6.361c0-3.014-0.854-5.16-2.559-6.439        c-1.709-1.281-4.375-1.922-8.004-1.922H270.1V234.737z M270.1,242.179v16.964h10.922c2.004,0,3.674-0.241,5.02-0.724        c1.348-0.479,2.43-1.119,3.242-1.918c0.814-0.801,1.402-1.732,1.762-2.801c0.361-1.067,0.537-2.199,0.537-3.398        c0-1.254-0.197-2.383-0.598-3.382s-1.025-1.856-1.881-2.562c-0.852-0.706-1.949-1.247-3.279-1.621        c-1.334-0.373-2.947-0.559-4.842-0.559H270.1z"
                    />
                    <path
                      fill="var(--color-secondary-dark)"
                      d="M367.393,267.546h-8.322c-0.934,0-1.699-0.232-2.299-0.703c-0.6-0.466-1.033-1.045-1.301-1.739        l-4.32-11.803h-23.965l-4.322,11.803c-0.215,0.614-0.633,1.175-1.262,1.678c-0.625,0.511-1.393,0.765-2.301,0.765h-8.359        l22.727-57.855h10.998L367.393,267.546z M329.945,245.696h18.443l-7.041-19.24c-0.32-0.854-0.676-1.862-1.059-3.023        c-0.389-1.159-0.768-2.419-1.143-3.778c-0.373,1.359-0.742,2.627-1.1,3.8c-0.361,1.176-0.713,2.201-1.063,3.08        L329.945,245.696z"
                    />
                    <path
                      fill="var(--color-secondary-dark)"
                      d="M386.596,209.749c0.322,0.04,0.609,0.12,0.861,0.242c0.252,0.12,0.502,0.296,0.74,0.521        c0.238,0.228,0.504,0.526,0.801,0.902l30.365,38.688c-0.105-0.936-0.178-1.849-0.219-2.741        c-0.041-0.896-0.063-1.729-0.063-2.503V209.69h9.484v57.855h-5.561c-0.855,0-1.561-0.136-2.121-0.402        c-0.563-0.265-1.109-0.745-1.643-1.441l-30.244-38.527c0.08,0.854,0.139,1.698,0.18,2.541        c0.039,0.843,0.059,1.607,0.059,2.301v35.529h-9.479V209.69h5.637C385.877,209.69,386.275,209.711,386.596,209.749z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    fill="var(--color-secondary-dark)"
                    d="M125.674,267.546h-8.32c-0.932,0-1.701-0.232-2.299-0.703c-0.602-0.466-1.037-1.045-1.303-1.739       l-4.32-11.803H85.465l-4.32,11.803c-0.213,0.614-0.635,1.175-1.262,1.678c-0.623,0.511-1.393,0.765-2.299,0.765h-8.361       l22.727-57.855h11.002L125.674,267.546z M88.227,245.696h18.445l-7.043-19.24c-0.32-0.854-0.672-1.862-1.057-3.023       c-0.391-1.159-0.768-2.419-1.143-3.778c-0.375,1.359-0.742,2.627-1.102,3.8c-0.359,1.176-0.713,2.201-1.063,3.08       L88.227,245.696z"
                  />
                  <path
                    fill="var(--color-secondary-dark)"
                    d="M170.268,220.213c-0.293,0.589-0.633,1.001-1.02,1.243c-0.387,0.236-0.846,0.358-1.381,0.358       c-0.533,0-1.133-0.208-1.801-0.62c-0.666-0.413-1.451-0.875-2.359-1.38c-0.91-0.507-1.967-0.969-3.18-1.382       c-1.217-0.414-2.648-0.621-4.303-0.621c-1.494,0-2.795,0.179-3.9,0.541c-1.105,0.358-2.041,0.861-2.801,1.502       c-0.76,0.637-1.328,1.407-1.697,2.301c-0.375,0.895-0.563,1.874-0.563,2.938c0,1.363,0.381,2.496,1.141,3.401       c0.76,0.909,1.764,1.679,3.021,2.319c1.252,0.643,2.68,1.217,4.279,1.722c1.6,0.509,3.234,1.049,4.902,1.62       c1.664,0.574,3.301,1.241,4.902,2.002c1.6,0.759,3.023,1.722,4.279,2.88c1.254,1.161,2.26,2.581,3.021,4.263       c0.758,1.678,1.141,3.72,1.141,6.121c0,2.613-0.449,5.06-1.342,7.341c-0.896,2.28-2.201,4.269-3.922,5.961       c-1.719,1.693-3.818,3.029-6.303,4.002c-2.479,0.972-5.32,1.458-8.52,1.458c-1.842,0-3.654-0.179-5.441-0.539       c-1.787-0.358-3.5-0.873-5.141-1.539c-1.641-0.667-3.174-1.468-4.602-2.401c-1.426-0.933-2.701-1.974-3.822-3.121l3.123-5.162       c0.291-0.371,0.646-0.685,1.059-0.939c0.414-0.254,0.873-0.38,1.383-0.38c0.664,0,1.385,0.274,2.158,0.822       c0.775,0.545,1.688,1.151,2.742,1.816c1.053,0.671,2.293,1.279,3.719,1.822c1.428,0.545,3.141,0.821,5.143,0.821       c3.066,0,5.441-0.728,7.119-2.183c1.684-1.454,2.523-3.542,2.523-6.26c0-1.52-0.383-2.759-1.141-3.72       c-0.76-0.96-1.768-1.769-3.021-2.423c-1.252-0.651-2.682-1.206-4.283-1.66c-1.598-0.451-3.225-0.945-4.879-1.481       c-1.652-0.531-3.281-1.17-4.883-1.919c-1.598-0.745-3.023-1.72-4.279-2.921c-1.254-1.199-2.26-2.701-3.02-4.502       c-0.762-1.798-1.141-4.019-1.141-6.659c0-2.106,0.42-4.163,1.26-6.161c0.842-2.001,2.066-3.774,3.682-5.322       c1.613-1.548,3.594-2.787,5.941-3.721c2.348-0.935,5.025-1.399,8.039-1.399c3.416,0,6.564,0.534,9.445,1.6       c2.879,1.065,5.334,2.56,7.357,4.479L170.268,220.213z"
                  />
                  <path
                    fill="var(--color-secondary-dark)"
                    d="M244.15,238.618c0,4.239-0.707,8.168-2.123,11.78c-1.412,3.617-3.4,6.743-5.961,9.383       c-2.559,2.643-5.641,4.701-9.242,6.185c-3.6,1.478-7.588,2.217-11.961,2.217c-4.375,0-8.369-0.739-11.984-2.217       c-3.615-1.483-6.707-3.542-9.281-6.185c-2.574-2.64-4.566-5.766-5.982-9.383c-1.412-3.612-2.117-7.541-2.117-11.78       c0-4.241,0.705-8.167,2.117-11.783c1.416-3.614,3.408-6.74,5.982-9.38c2.574-2.645,5.666-4.703,9.281-6.185       s7.609-2.219,11.984-2.219c4.373,0,8.361,0.745,11.961,2.24c3.602,1.494,6.684,3.553,9.242,6.18       c2.561,2.631,4.549,5.749,5.961,9.363C243.443,230.451,244.15,234.377,244.15,238.618z M233.105,238.618       c0-3.174-0.418-6.022-1.26-8.542c-0.84-2.522-2.047-4.663-3.619-6.422c-1.572-1.76-3.49-3.107-5.742-4.042       c-2.254-0.93-4.795-1.401-7.621-1.401c-2.828,0-5.377,0.472-7.643,1.401c-2.268,0.935-4.197,2.282-5.779,4.042       c-1.59,1.759-2.809,3.899-3.664,6.422c-0.854,2.52-1.277,5.368-1.277,8.542s0.424,6.021,1.277,8.542       c0.855,2.521,2.074,4.653,3.664,6.401c1.582,1.746,3.512,3.088,5.779,4.021c2.266,0.934,4.814,1.4,7.643,1.4       c2.826,0,5.367-0.467,7.621-1.4c2.252-0.933,4.17-2.274,5.742-4.021c1.572-1.748,2.779-3.881,3.619-6.401       C232.688,244.64,233.105,241.792,233.105,238.618z"
                  />
                  <path
                    fill="var(--color-secondary-dark)"
                    d="M259.338,267.546V209.69h19.963c3.791,0,7.021,0.36,9.701,1.081c2.684,0.721,4.883,1.746,6.604,3.082       c1.719,1.331,2.982,2.947,3.783,4.84c0.799,1.894,1.197,4.028,1.197,6.399c0,1.363-0.199,2.661-0.598,3.904       c-0.402,1.241-1.021,2.398-1.863,3.478c-0.84,1.08-1.906,2.063-3.199,2.943c-1.295,0.878-2.836,1.626-4.623,2.239       c7.951,1.788,11.922,6.079,11.922,12.883c0,2.455-0.465,4.722-1.398,6.802c-0.936,2.081-2.293,3.873-4.082,5.381       c-1.783,1.51-3.988,2.687-6.6,3.54c-2.615,0.854-5.602,1.283-8.963,1.283H259.338z M270.1,234.737h8.6       c3.656,0,6.43-0.666,8.324-2.002c1.895-1.331,2.84-3.452,2.84-6.361c0-3.014-0.854-5.16-2.559-6.439       c-1.709-1.281-4.375-1.922-8.004-1.922H270.1V234.737z M270.1,242.179v16.964h10.922c2.004,0,3.674-0.241,5.02-0.724       c1.348-0.479,2.43-1.119,3.242-1.918c0.814-0.801,1.402-1.732,1.762-2.801c0.361-1.067,0.537-2.199,0.537-3.398       c0-1.254-0.197-2.383-0.598-3.382s-1.025-1.856-1.881-2.562c-0.852-0.706-1.949-1.247-3.279-1.621       c-1.334-0.373-2.947-0.559-4.842-0.559H270.1z"
                  />
                  <path
                    fill="var(--color-secondary-dark)"
                    d="M367.393,267.546h-8.322c-0.934,0-1.699-0.232-2.299-0.703c-0.6-0.466-1.033-1.045-1.301-1.739       l-4.32-11.803h-23.965l-4.322,11.803c-0.215,0.614-0.633,1.175-1.262,1.678c-0.625,0.511-1.393,0.765-2.301,0.765h-8.359       l22.727-57.855h10.998L367.393,267.546z M329.945,245.696h18.443l-7.041-19.24c-0.32-0.854-0.676-1.862-1.059-3.023       c-0.389-1.159-0.768-2.419-1.143-3.778c-0.373,1.359-0.742,2.627-1.1,3.8c-0.361,1.176-0.713,2.201-1.063,3.08L329.945,245.696       z"
                  />
                  <path
                    fill="var(--color-secondary-dark)"
                    d="M386.596,209.749c0.322,0.04,0.609,0.12,0.861,0.242c0.252,0.12,0.502,0.296,0.74,0.521       c0.238,0.228,0.504,0.526,0.801,0.902l30.365,38.688c-0.105-0.936-0.178-1.849-0.219-2.741       c-0.041-0.896-0.063-1.729-0.063-2.503V209.69h9.484v57.855h-5.561c-0.855,0-1.561-0.136-2.121-0.402       c-0.563-0.265-1.109-0.745-1.643-1.441l-30.244-38.527c0.08,0.854,0.139,1.698,0.18,2.541c0.039,0.843,0.059,1.607,0.059,2.301       v35.529h-9.479V209.69h5.637C385.877,209.69,386.275,209.711,386.596,209.749z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <path
              fill="var(--color-secondary-dark)"
              d="M258.76,169.69h-8.844h-0.16h-0.162c-0.256,0.004-0.51,0.008-0.764,0.021    c-0.252-0.014-0.512-0.018-0.781-0.021c-3.129-0.047-6.211-0.391-9.221-1v-21.462c3.133,1.109,6.486,1.744,9.998,1.744    c16.563,0,29.988-13.43,29.988-29.99c0-16.565-13.26-30.525-29.988-29.987c-16.193,0.517-29.363,12.876-29.934,28.915h-0.053    v1.033c0,0.015-0.002,0.023-0.002,0.039c0,0.012,0.002,0.029,0.002,0.037v40.666c-1.918-1.445-3.746-3.039-5.465-4.777    c-9.248-9.331-14.375-21.708-14.439-34.749c0-0.122-0.004-0.232-0.004-0.351c0-0.115,0.004-0.23,0.004-0.349v-12.631h-0.086    V64.273c0-7.983-6.475-14.456-14.455-14.456c-1.652,0.004-4.02,0-5.041,0h-0.35h-0.148v27.801v41.842    c0,0.118-0.008,0.233-0.008,0.349c0,0.118,0.008,0.229,0.008,0.351c0.172,35.056,26.115,63.974,59.85,68.867    c2.959,0.433,5.973,0.695,9.041,0.743c0.361,0.002,0.721,0.025,1.084,0.025c0.365,0,0.723-0.023,1.086-0.025h28.898v-0.575    C278.506,178.376,269.658,169.69,258.76,169.69z"
            />
            <path
              fill="var(--color-secondary-dark)"
              d="M318.805,119.457c-0.182-37.212-29.031-68.706-66.111-69.64h-2.352h-0.887h-29.951h-0.66v4.508    c0,8.222,6.35,14.95,14.414,15.573h2.443h14.641h2.078c25.424,0.771,46.176,22.996,46.305,49.559    c0,0.116,0.049,51.154,0.084,51.623c0.719,10.432,9.381,18.669,19.996,18.689v-40.184v-29.427c0-0.122,0.012-0.232,0.012-0.351    C318.816,119.693,318.805,119.573,318.805,119.457z"
            />
          </g>
        </g>
      </svg>
      <span class="loaders"> </span>
    </div>
  `,
  styles: [
    `
      .container_block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        transition: all 0.3s linear;
        background-color: var(--color-contrast);
        z-index: 100;
      }
      .container_block svg {
        width: 120px;
        animation-name: animateLogo;
        animation-iteration-count: infinite;
        animation-duration: 2.5s;
        animation-direction: alternate;
        animation-timing-function: linear;
      }
      @keyframes animateLogo {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(1.1);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }
      .loaders,
      .loaders:before,
      .loaders:after {
        border-radius: 50%;
        width: 2em;
        height: 2em;
        animation-fill-mode: both;
        animation: bblFadInOut 1.8s infinite ease-in-out;
      }
      .loaders {
        color: var(--color-primary-dark);
        font-size: 5px;
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        animation-delay: -0.16s;
        margin-top: -15px;
      }
      .loaders:before,
      .loaders:after {
        content: '';
        position: absolute;
        top: 0;
      }
      .loaders:before {
        left: -3.5em;
        animation-delay: -0.32s;
      }
      .loaders:after {
        left: 3.5em;
      }

      @keyframes bblFadInOut {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
    `,
  ],
})
export class BlockTemplateComponent {}
