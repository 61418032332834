<div class="buttons-table">
  <div class="buttons-table_left">
      <span></span>
    </div>
  <div class="buttons-table_right">
      <button
      >
        ATC
      </button>
      <button
      >
        LINKSER
      </button>
      <button
      >
        UNIRED
      </button>
    </div>
</div>
<table class="table-comisions">
<tr>
  <th class="center-header header-blue" rowspan="2">N°</th>
  <th class="center-header header-blue" rowspan="2">Red entidad emisora</th>
  <th class="center-header header-blue" rowspan="2">Entidad emisora</th>
  <th class="center-header header-blue" rowspan="2">Tipo de tarjeta</th>
  <th class="center-header header-blue" rowspan="2">BIM</th>
  <th class="center-header header-gray" colspan="5">
      <div class="u-flex-center">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
  </th>
</tr>
<tr>
  <td class="center-header header-gray">Retiro en Bs.</td>
  <td class="center-header header-gray">Consulta de saldo</td>
  <td class="center-header header-gray">Retiro en $us.</td>
  <td class="center-header header-gray">Mini extracto</td>
  <td class="center-header header-gray">Extracto</td>
</tr>
<tbody>
  <tr *ngFor="let item of [1,1,1,1,1,1,1,1,1,1]; let i = index">
    <td class="center-header u-p-05">
      <div class="u-flex-center" style="width: 100%;">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
    </td>
    <td class="center-header u-p-05">
      <div class="u-flex-center" style="width: 100%;">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
    </td>
    <td class="center-header u-p-05">
      <div class="u-flex-center" style="width: 100%;">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
    </td>
    <td class="center-header">
      <div class="u-flex-center" style="width: 100%;">
        <div
          class="skeleton-animation"
          style="width: 80%; height: 18px"
        ></div>
      </div>
    </td>
    <td class="center-header">
      <div class="u-flex-center" style="width: 100%;">
        <div
          class="skeleton-animation"
          style="width: 80%; height: 18px"
        ></div>
      </div>
    </td>
    <td class="center-header">
      <div class="u-flex-center" style="width: 100%;">
        <div
          class="skeleton-animation"
          style="width: 80%; height: 18px"
        ></div>
      </div>
    </td>
    <td class="center-header">
      <div class="u-flex-center" style="width: 100%;">
        <div
          class="skeleton-animation"
          style="width: 80%; height: 18px"
        ></div>
      </div>
    </td>
    <td class="center-header">
      <div class="u-flex-center" style="width: 100%;">
        <div
          class="skeleton-animation"
          style="width: 80%; height: 18px"
        ></div>
      </div>
    </td>
    <td class="center-header">
      <div class="u-flex-center" style="width: 100%;">
        <div
          class="skeleton-animation"
          style="width: 80%; height: 18px"
        ></div>
      </div>
    </td>
    <td class="center-header">
      <div class="u-flex-center" style="width: 100%;">
        <div
          class="skeleton-animation"
          style="width: 80%; height: 18px"
        ></div>
      </div>
    </td>
  </tr>
</tbody>
</table>