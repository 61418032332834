<div class="container-multiitems u-margin-top-6">
  <p-carousel
    [value]="[1, 1, 1, 1, 1, 1, 1]"
    styleClass="custom-carousel"
    [numVisible]="3"
    [numScroll]="3"
    [circular]="true"
    [autoplayInterval]="3000"
    [responsiveOptions]="responsiveOptions"
  >
    <ng-template let-item pTemplate="item">
      <div class="cards-item">
        <div class="cards-item-content">
          <div class="container-multi-card">
            <div class="content-card">
              <div class="content-card_image" style="width: 300PX;height: 170px;">
                <div
                  class="skeleton-animation"
                  style="width: 100%; height: 100%"
                ></div>
              </div>

              <div class="content-card_description" style="width: 50%">
                <h3 class="skeleton-animation" style="height: 18px;"></h3>
                <div class="container-cards_button u-flex-center">
                  <div class="skeleton-animation" style="height: 20px;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
