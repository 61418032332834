import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarrucelComponent } from './carrucel.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { SkeletonModule } from '../skeleton/skeleton.module';

@NgModule({
  declarations: [CarrucelComponent],
  imports: [CommonModule, NgbCarouselModule, SharedModule, SkeletonModule],
  exports: [CarrucelComponent],
})
export class CarrucelModule {}
