<table role="table" id="normative">
  <thead role="rowgroup">
    <tr role="row">
      <th class="header-blue">Fuente</th>
      <th role="columnheader" class="header-blue">N° de Ref</th>
      <th role="columnheader" class="header-blue">Fecha de publicación</th>
      <th role="columnheader" class="header-blue">Norma</th>
      <th role="columnheader" class="header-blue">Palabra clave</th>
      <th role="columnheader" class="header-blue col-resume">Resumen</th>
      <th role="columnheader" class="header-blue">
        <div>
          <span>Comparativo modificación</span>
          <p class="subtitle-table">Norma/ASFI/Otras</p>
        </div>
      </th>
    </tr>
  </thead>
  <tbody role="rowgroup">
    <tr role="row" *ngFor="let item of [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]">
      <td role="cell">
        <div class="u-flex-center">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
      </td>
      <td role="cell">
        <div class="u-flex-center">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
      </td>
      <td role="cell">
        <div class="u-flex-center">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
      </td>
      <td role="cell">
        <div class="u-flex-center">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
      </td>
      <td role="cell">
        <div class="u-flex-center">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
      </td>
      <td role="cell" class="u-text-start col-resume">
        <div class="u-flex-center">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
      </td>
      <td role="cell">
        <div class="u-flex-center">
          <div
            class="skeleton-animation"
            style="width: 80%; height: 18px"
          ></div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
