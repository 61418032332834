<div class="content-options-menu">
  <div
    class="card-item u-cursor-pointer"
    style="opacity: 1"
    *ngFor="let item of [1, 1, 1, 1]"
  >
    <svg class="card-item_icon skeleton-animation">
      <use attr.xlink:href="assets/svg/icons.svg#{{ item.icon }}"></use>
    </svg>
    <h3 class="skeleton-animation" style="height: 15px; width: 85%">
      {{ item.title }}
    </h3>
  </div>
</div>
