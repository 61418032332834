import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-carrucels',
  templateUrl: './skeleton-carrucels.component.html',
  styleUrls: ['./skeleton-carrucels.component.scss']
})
export class SkeletonCarrucelsComponent implements OnInit {

  responsiveOptions = [
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '1133px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '915px',
      numVisible: 1,
      numScroll: 1,
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
