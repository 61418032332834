import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'formatHour'
})
export class FormatHourPipe implements PipeTransform {
  /**
  * @FormatDatePipe Clase para formatear las fechas
  */
   transform(value: any, type?: string): any {
    if (type === 'only-date') {
      return moment(value, ["HH:mm:ss"]).format("HH:mm a");
    }
    if (value){
      const DATA = moment(value, ["HH:mm:ss"]).format("HH:mm a");
      if (DATA === 'Invalid date') {
        return value;
      }
      return DATA;
    }
  }
}
