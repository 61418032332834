<div class="grid-col-2">
  <div class="container-discover">
    <ngb-carousel
      class="carrucel-discover"
      [showNavigationArrows]="false"
      [showNavigationIndicators]="true"
    >
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <div
            class="skeleton-animation imgen-discover"
            style="margin: 2rem; height: 175px"
          ></div>
          <div class="discover-description">
            <div
              class="skeleton-animation"
              style="height: 18px; width: 20%"
            ></div>
            <div
              class="skeleton-animation"
              style="height: 12px; width: 35%; margin-top: 5px"
            ></div>

            <div
              class="skeleton-animation"
              style="height: 12px; width: 80%; margin-top: 5px"
            ></div>
            <div
              class="skeleton-animation"
              style="height: 12px; width: 70%; margin-top: 5px"
            ></div>
            <div
              class="skeleton-animation"
              style="height: 12px; width: 70%; margin-top: 5px"
            ></div>
            <div
              class="skeleton-animation"
              style="height: 12px; width: 40%; margin-top: 5px"
            ></div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>

  <div class="container-access u-flex-center">
    <div class="card-access">
      <div class="card-access_icon">
        <div
          class="skeleton-animation"
          style="width: 120px; height: 120px"
        ></div>
      </div>
      <div class="card-access_description">
        <div class="skeleton-animation" style="height: 18px; width: 20%"></div>
        <div
          class="skeleton-animation"
          style="height: 12px; width: 80%; margin-top: 5px"
        ></div>
        <div
          class="skeleton-animation"
          style="height: 12px; width: 70%; margin-top: 5px"
        ></div>
        <div
          class="skeleton-animation"
          style="height: 12px; width: 70%; margin-top: 5px"
        ></div>
        <div
          class="skeleton-animation"
          style="height: 12px; width: 40%; margin-top: 5px"
        ></div>
        <div class="card-access_button">
          <div
            class="skeleton-animation"
            style="height: 20px; width: 100px; margin-top: 1.5rem"
          ></div>
          <button class="button-icon">
            <div
              class="skeleton-animation"
              style="height: 20px; width: 100px; margin-top: 1.5rem"
            ></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
