<div class="container-skeleton-calendary">
  <div
    class="row container-skeleton-calendary_item"
    [ngClass]="{
      'position-end': i === 0,
      'position-start': i === 5
    }"
    *ngFor="let item of [1, 1, 1, 1, 1, 1]; let i = index"
  >
    <ng-container *ngIf="i === 0">
      <div class="skeleton-title">
        <div
          class="skeleton-animation-white"
          style="
            width: 45%;
            height: 18px;
            margin-bottom: 0.5rem;
            margin-right: 10px;
            margin-top: 10px;
          "
        ></div>
      </div>
      <div
        class="skeleton-animation-white"
        style="
          height: 20px;
          width: 20px;
          margin: 3px 10px 0 10px;
          justify-content: flex-end;
        "
        *ngFor="let item of [1, 1, 1]"
      ></div>
    </ng-container>

    <ng-container *ngIf="i != 0 && i != 5">
      <div
        class="skeleton-animation-white"
        style="height: 20px; width: 20px; margin: 3px 10px 0 10px"
        *ngFor="let item of [1, 1, 1, 1, 1, 1, 1]"
      ></div>
    </ng-container>

    <ng-container *ngIf="i === 5">
      <div
        class="skeleton-animation-white"
        style="height: 20px; width: 20px; margin: 3px 10px 0 10px"
        *ngFor="let item of [1, 1]"
      ></div>
    </ng-container>
  </div>
</div>
