import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatDatePipe } from './format-date.pipe';
import { FormatHourPipe } from './format-hour';

@NgModule({
  declarations: [FormatDatePipe,FormatHourPipe],
  imports: [
    CommonModule
  ],exports:[FormatDatePipe,FormatHourPipe]
})
export class PipeModule { }
