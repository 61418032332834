import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NopagefoundComponent } from './components/nopagefound/nopagefound.component';
import { PrivacityPoliciesComponent } from './pages/privacity-policies/privacity-policies.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full',
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./pages/public-section/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'restricted',
    loadChildren: () =>
      import('./pages/restriction-section/restriction-section.module').then(
        (m) => m.RestrictionSectionModule
      ),
  },
  {
    path: 'privacy-policies',
    component: PrivacityPoliciesComponent,
  },
  {
    path: '**',
    component: NopagefoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      useHash: true,
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
