<div class="container-memories">
  <div class="container-memories_image" style="height: 360px">
    <div class="skeleton-animation" style="width: 100%; height: 100%"></div>
  </div>
  <div class="container-description">
    <h3
      class="skeleton-animation"
      style="height: 22px;  width: 50%"
    ></h3>
    <p
      class="skeleton-animation"
      style="height: 18px;  width: 90%; margin-bottom: 10px"
    ></p>
    <p
      class="skeleton-animation"
      style="height: 18px;  width: 90%; margin-bottom: 10px"
    ></p>
    <p
      class="skeleton-animation"
      style="height: 18px;  width: 90%; margin-bottom: 10px"
    ></p>
    <p
      class="skeleton-animation"
      style="height: 18px;  width: 70%; margin-bottom: 10px"
    ></p>
    <p
      class="skeleton-animation"
      style="height: 18px;  width: 50%; margin-bottom: 10px"
    ></p>

    <div class="container-cards_button">
      <div class="skeleton-animation"></div>
    </div>
  </div>
</div>
