<div
  class="items-activities"
  style="opacity: 1"
  *ngFor="let item of [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]"
>
  <div
    class="time-text skeleton-animation-white"
    style="height: 20px; width: 55px;"
  ></div>
  <div style="width: calc(100% - 55px)">
    <p
      class="skeleton-animation-white"
      style="height: 10px; width: 90%; margin-top: 7px"
    ></p>
    <p
      class="skeleton-animation-white"
      style="height: 10px; width: 90%; margin-top: 7px"
    ></p>
    <p
      class="skeleton-animation-white"
      style="height: 10px; width: 60%; margin-top: 7px"
    ></p>
  </div>
</div>
