import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VarApi } from 'src/app/setting/varApi';
import { ApiService } from '../../interceptors/api.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  refresh: Subject<any> = new Subject();
  constructor(private _api: ApiService) {}

  /**
   * get data events calendar
   * @returns
   */
  getEventCalendar(date: string, limit: number): Observable<any> {
    return this._api.getDataValues(`${VarApi.URL_CALENDAR}?date=${date}`);
  }

  /**
   * get data events calendar
   * @returns
   */
  getAllCalendar(): Observable<any> {
    return this._api.getDataValues(`${VarApi.URL_CALENDAR}`);
  }
}
