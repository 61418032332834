<div class="container-banner-images">
  <img
    class="image-banner"
    [src]="configData.image"
    alt="{{ configData.title }}"
    [defaultImage]="'assets/images/default.png'"
    [lazyLoad]="configData.image"
  />
  <div class="banner-title in-right" id="title-banner">
    <h1>{{ configData.title }}</h1>
  </div>
</div>
