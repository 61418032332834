import { Component, Input, OnInit } from '@angular/core';
import { Ititle } from 'src/app/interfaces/Ititle.interfaces';

@Component({
  selector: 'app-skeleton-title',
  templateUrl: './skeleton-title.component.html',
  styleUrls: ['./skeleton-title.component.scss']
})
export class SkeletonTitleComponent implements OnInit {
  @Input() dataConfig:Ititle; 
  constructor() { }

  ngOnInit(): void {
  }

}
