import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleComponent } from '../components/title/title.component';
import { BannerImagesComponent } from '../components/banner-images/banner-images.component';
import { ImageBrokenDirective } from '../directives/image-broken.directive';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [TitleComponent, BannerImagesComponent, ImageBrokenDirective],
  imports: [CommonModule, LazyLoadImageModule],
  exports: [TitleComponent, BannerImagesComponent, ImageBrokenDirective],
})
export class SharedModule {}
