export class LocalStorageStg {
  // settings storage
  static URL_API = '__api_route__';
  static TOKEN = '__app_token_web__';
  static USER = '__app_user_web__';
  static USER_NAME = '__app_userName_web__';
  static MESSAGE = '__app_message__';
  static CHANGE_PROFILE = '__app_change_profile__';
  static DESCRIPTION_REPRESENTATION = '__app_description-representation__';
  static SEND_CODE = '__app_send_code__';
  static SEND_CODE_MOBILE = '__app_send_code_mobile__';
  static SEARCH = '__app_search__';
  static EVENT_ID = '__app_event_id__';
  static FORWARD = '__app_forward__';

  // public section
  static INSTITUTIONAL = '__app_institutional__';
  static PUBLICATION = '__app_publication__';
  static PRESS = '__app_press__';
  static EVENT = '__app_event__';
  static INITIATIVE = '__app_initiative__';
  static CONTACT = '__app_contact__';

  // generic
  static GENERIC_PUBLICATIONS = '__app_generic_publications__';
  static GENERIC_PRESS = '__app_generic_press__';
  static GENERIC_INITIATIVE = '__app_generic_initiative__';

  // home generic
  static HOME_PUBLICATIONS = '__app_home_publications__';
  static HOME_PRESS = '__app_home_press__';

  static FORM_PRINT_OBTEIN = '__form_print_obtain__';
  static FORM_PRIN_RENEWAL = '__form_print_renewal__';
  static FORM_PRIN_TYPE = '__form_print_type__';

  //filter category
  static FILTER_EVENT = '__app_filter_event__';
  static FILTER_PRESS = '__app_filter_press__';
  static FILTER_PUBLICATION = '__app_filter_publication__';

  // restricted section
  static EXCLUSIVE_CONTENT = '__exclusive_content__';
  static GROUP = '__group__';
  static TOOLS = '__tools__';
  static DIGITAL_SIGNATURE = '__digital_signature__';
  static DIRECTORY = '__directory__';
  static SEARCH_DIRECTORY = '__search_directory__';
  static FORM_REGISTER = '__form_register__';
  static FLAG_VERIFY = '__flag_verify__';

  // interval time
  static INTERVAL_TIME = '__interval_time__';
}
