<button (click)="actionSidebar()" class="button-colpase">
  <svg>
    <use href="assets/svg/icons.svg#svg-menu-colp" />
  </svg>
</button>
<div class="content-side-calendar" id="scroll-activities">
  <header class="container-side-calendar">
    <button (click)="actionSidebar()" class="button-colpase-close">
      <svg style="width: 15px; height: 15px">
        <use href="assets/svg/icons.svg#svg-close" />
      </svg>
    </button>
    <ng-container *ngIf="showSearch">
      <form method="get" class="form_search" fxLayout="row">
        <input
          type="text"
          id="txt_buscar"
          name="buscadorPro"
          [(ngModel)]="val"
          placeholder="Buscar..."
          class="form_search__input"
          (keyup.enter)="search()"
          autocomplete="off"
        />
        <button
          (click)="openSearch()"
          type="button"
          class="form_search__button form_search__button--white"
          *ngIf="!isShowingInputSearch; else btnClose"
        >
          <svg class="search-button">
            <use href="assets/svg/icons.svg#svg-search" />
          </svg>
        </button>
        <ng-template #btnClose>
          <button
            (click)="search()"
            type="button"
            class="form_search__button form_search__button--blue"
          >
            <svg class="search-button">
              <use href="assets/svg/icons.svg#svg-search" />
            </svg>
          </button>
        </ng-template>
      </form>
    </ng-container>

    <button class="button-profile" (click)="profileUser()">
      <svg>
        <use href="assets/svg/icons.svg#svg-config" />
      </svg>
    </button>
  </header>

  <ng-container *ngIf="isShowLoader; else profile">
    <app-skeleton-icon-profile></app-skeleton-icon-profile>
  </ng-container>

  <ng-template #profile>
    <div class="profile-user in-right">
      <svg>
        <use href="assets/svg/icons.svg#svg-user"></use>
      </svg>
      <h3>Hola {{User.name}}</h3>
    </div>
  </ng-template>
  <div class="u-flex-center calendar-content">
    <ng-container *ngIf="isShowLoader; else calendary">
      <app-skeleton-calendary></app-skeleton-calendary>
    </ng-container>

    <ng-template #calendary>
      <div class="in-right">
        <app-calendar (onClick)="showEvents($event)"></app-calendar>
      </div>
    </ng-template>
  </div>
  <h3 class="title-activities">PRÓXIMAS ACTIVIDADES</h3>
  <div class="container-activities" id="scroll-activities">
    <ng-container *ngIf="isShowLoaderActivities; else Activities">
      <app-skeleton-activities></app-skeleton-activities>
    </ng-container>

    <ng-template #Activities>
      <div class="items-activities" *ngFor="let item of dataEvent" (click)="redirectUrlEvent(item)">
        <div class="time-text">{{ item.startTime | formatHour }}</div>
        <p>
          {{ item.description }}
        </p>
      </div>
    </ng-template>
    <ng-container *ngIf="!isShowLoaderActivities && dataEvent.length === 0">
      <div class="fadeIn">
        <h3 class="not-data">No se encontrarón eventos</h3>
      </div>
    </ng-container>
  </div>
  <div class="container-cards_button button-sider">
    <button
      matRipple
      [matRippleColor]="'#ffffff38'"
      (click)="returnWeb(confirmModalReturn)"
    >
      <span> Volver a la web</span>
    </button>
    <button
      matRipple
      [matRippleColor]="'#ffffff38'"
      (click)="logout(confirmModalLogout)"
    >
      <span>Cerrar sesión</span>
      <svg>
        <use href="assets/svg/icons.svg#svg-logout" />
      </svg>
    </button>
  </div>
</div>

<!-- logout -->
<ng-template #confirmModalLogout >
  <app-modal-confirmation
  [@modalAnimation]
    [titleModal]="'¿Está seguro de cerrar sesión?'"
    (onAccept)="actionsModalLogout('confirm')"
    (onCancel)="actionsModalLogout('cancel')"
  >
  </app-modal-confirmation>
</ng-template>

<!-- go to web -->
<ng-template #confirmModalReturn >
  <div [@modalAnimation]>
    <app-modal-confirmation
    [titleModal]="'¿Está seguro de volver a la web?'"
    (onAccept)="actionsModalReturn('confirm')"
    (onCancel)="actionsModalReturn('cancel')"
  >
  </app-modal-confirmation>
  </div>
 
</ng-template>
