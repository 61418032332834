import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-table-commissions',
  templateUrl: './skeleton-table-commissions.component.html',
  styleUrls: ['../../table-commissions/table-commissions.component.scss'],
})
export class SkeletonTableCommissionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
