import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonCardOptionsComponent } from './skeleton-card-options/skeleton-card-options.component';
import { SkeletonCarrucelUpcomingEventsComponent } from './skeleton-carrucel-upcoming-events/skeleton-carrucel-upcoming-events.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { SkeletonCarruselInitiativesComponent } from './skeleton-carrusel-initiatives/skeleton-carrusel-initiatives.component';
import { SkeletonCarrucelDiscoverComponent } from './skeleton-carrucel-discover/skeleton-carrucel-discover.component';
import { SkeletonCarrucelBannerComponent } from './skeleton-carrucel-banner/skeleton-carrucel-banner.component';
import { SkeletonTitleComponent } from './skeleton-title/skeleton-title.component';
import { SkeletonBannerImageComponent } from './skeleton-banner-image/skeleton-banner-image.component';
import { SkeletonActivitiesComponent } from './skeleton-activities/skeleton-activities.component';
import { SkeletonMenuRestrictedComponent } from './skeleton-menu-restricted/skeleton-menu-restricted.component';
import { SkeletonCardDestacadoComponent } from './skeleton-card-destacado/skeleton-card-destacado.component';
import { SkeletonMapComponent } from './skeleton-map/skeleton-map.component';
import { SkeletonCalendaryComponent } from './skeleton-calendary/skeleton-calendary.component';
import { SkeletonIconProfileComponent } from './skeleton-icon-profile/skeleton-icon-profile.component';
import { SkeletonCarrucelCardComponent } from './skeleton-carrucel-card/skeleton-carrucel-card.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SkeletonMultiItemsComponent } from './skeleton-multi-items/skeleton-multi-items.component';
import { CarouselModule } from 'primeng/carousel';
import { SkeletonMemoriesInstitutionalsComponent } from './skeleton-memories-institutionals/skeleton-memories-institutionals.component';
import { SkeletonMemoriesComponent } from './skeleton-memories/skeleton-memories.component';
import { SkeletonTableComponent } from './skeleton-table/skeleton-table.component';
import { SkeletonTableCommissionsComponent } from './skeleton-table-commissions/skeleton-table-commissions.component';
import { SkeletonEventsCalendarComponent } from './skeleton-events-calendar/skeleton-events-calendar.component';
import { SkeletonNotificationsComponent } from './skeleton-notifications/skeleton-notifications.component';
import { SkeletonSearchItemComponent } from './skeleton-search-item/skeleton-search-item.component';
import { SkeletonCarrucelsComponent } from './skeleton-carrucels/skeleton-carrucels.component';

@NgModule({
  declarations: [
    SkeletonCardOptionsComponent,
    SkeletonCarrucelUpcomingEventsComponent,
    SkeletonCarruselInitiativesComponent,
    SkeletonCarrucelDiscoverComponent,
    SkeletonCarrucelBannerComponent,
    SkeletonTitleComponent,
    SkeletonBannerImageComponent,
    SkeletonActivitiesComponent,
    SkeletonMenuRestrictedComponent,
    SkeletonCardDestacadoComponent,
    SkeletonMapComponent,
    SkeletonCalendaryComponent,
    SkeletonIconProfileComponent,
    SkeletonCarrucelCardComponent,
    SkeletonMultiItemsComponent,
    SkeletonMemoriesInstitutionalsComponent,
    SkeletonMemoriesComponent,
    SkeletonTableComponent,
    SkeletonTableCommissionsComponent,
    SkeletonEventsCalendarComponent,
    SkeletonNotificationsComponent,
    SkeletonSearchItemComponent,
    SkeletonCarrucelsComponent
  ],
  imports: [
    CommonModule, 
    NgbCarouselModule,
    ScrollPanelModule,
    ScrollTopModule,
    CarouselModule
  ],
  exports: [
    SkeletonCardOptionsComponent,
    SkeletonCarrucelUpcomingEventsComponent,
    SkeletonCarruselInitiativesComponent,
    SkeletonCarrucelDiscoverComponent,
    SkeletonCarrucelBannerComponent,
    SkeletonTitleComponent,
    SkeletonBannerImageComponent,
    SkeletonActivitiesComponent,
    SkeletonMenuRestrictedComponent,
    SkeletonCardDestacadoComponent,
    SkeletonMapComponent,
    SkeletonCalendaryComponent,
    SkeletonIconProfileComponent,
    SkeletonCarrucelCardComponent,
    SkeletonMultiItemsComponent,
    SkeletonMemoriesInstitutionalsComponent,
    SkeletonMemoriesComponent,
    SkeletonTableComponent,
    SkeletonTableCommissionsComponent,
    SkeletonEventsCalendarComponent,
    SkeletonNotificationsComponent,
    SkeletonSearchItemComponent,
    SkeletonCarrucelsComponent
  ],
})
export class SkeletonModule {}
