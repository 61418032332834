import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionModal } from '../modal-responsive/modal-responsive.service';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: [],
})
export class ModalConfirmationComponent implements OnInit {
  @Output() onAccept = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @Input() titleModal: string = '¿Está seguro de guardar?';
  actionModal = ActionModal;

  buttonAccept: any = {
    title: 'Aceptar',
    color: 'confirmation',
  };
  buttonCancel: any = {
    title: 'Cancelar',
    color: 'cancel',
  };
  constructor() {}

  ngOnInit(): void {}
}
