import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalConfirmationComponent } from './modal-confirmation.component';
import { MatRippleModule } from '@angular/material/core';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [ModalConfirmationComponent],
  imports: [CommonModule, MatRippleModule, ButtonModule],
  exports: [ModalConfirmationComponent],
})
export class ModalConfirmationModule {}
