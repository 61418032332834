import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHomeDto } from 'src/app/interfaces/api/IHome.interface';
import { IPopupDto } from 'src/app/interfaces/api/IPopUp.interface';
import { ISectionDto } from 'src/app/interfaces/api/ISection.interface';
import { ISubsectionDto } from 'src/app/interfaces/api/ISubsections.interface';
import { ICategories } from 'src/app/interfaces/components/ICategories.interfaces';
import { VarApi } from 'src/app/setting/varApi';
import { ApiService } from '../interceptors/api.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private _api: ApiService) {}

  /**
   * Get social media
   */
  getSocialMedia(): Observable<any> {
    return this._api.getDataValues(`${VarApi.URL_SOCIAL_MEDIA}`);
  }

  /**
   * It returns an Observable of an array of ICategories
   * @returns An Observable of an array of ICategories.
   */
  getNavigationCategories(): Observable<Array<ICategories>> {
    return this._api.getDataValues(`${VarApi.URL_CATEGORIES}`);
  }

  /**
   * Get sections
   */
  getSections(id: number): Observable<Array<ISectionDto>> {
    return this._api.getDataValues(`${VarApi.URL_SECTIONS}?categoryId=${id}`);
  }

  /**
   * Get carrucel  components
   */
  getHomeCarrucel(): Observable<IHomeDto> {
    return this._api.getDataValues(`${VarApi.URL_HOME}`);
  }

  /**
   * Get subsections
   */
  getSubSections(sectionId: number): Observable<Array<ISubsectionDto>> {
    return this._api.getDataValues(
      `${VarApi.URL_SUBSECTIONS}?sectionId=${sectionId}`
    );
  }

  /**
   * It returns an Observable of type IPopupDto.
   * @returns Observable;IPopupDto;
   */
  getdataPopup(): Observable<IPopupDto> {
    return this._api.getDataValues(`${VarApi.URL_POPUP}/main`);
  }
}
