import * as moment from 'moment';
import { IMonth } from '../interfaces/components/IMonth.interface';

export class functionsGeneric {
  /**
   * @param {number} quantity - number - the number of years you want to generate
   * @returns An array of numbers.
   */
  getYears(quantity: number): Array<number> {
    let years: Array<number> = [];
    for (let i = 0; i < quantity; i++) {
      years.push(new Date().getFullYear() - i);
    }
    return years;
  }

  getMonths(): Array<IMonth> {
    let month: Array<IMonth> = [
      {
        id: 1,
        value: 'Enero',
      },
      {
        id: 2,
        value: 'Febrero',
      },
      {
        id: 3,
        value: 'Marzo',
      },
      {
        id: 4,
        value: 'Abril',
      },
      {
        id: 5,
        value: 'Mayo',
      },
      {
        id: 6,
        value: 'Junio',
      },
      {
        id: 7,
        value: 'Julio',
      },
      {
        id: 8,
        value: 'Agosto',
      },
      {
        id: 9,
        value: 'Septiembre',
      },
      {
        id: 10,
        value: 'Octubre',
      },
      {
        id: 11,
        value: 'Noviembre',
      },
      {
        id: 12,
        value: 'Diciembre',
      },
    ];
    return month;
  }

  downloadFile(fileName, blobResponse) {
    let blob = new Blob([blobResponse], { type: 'application/pdf' });
    var downloadURL = window.URL.createObjectURL(blobResponse);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
  }

  /**
   * summarize by days
   * @param data
   */
  summaryDay(data: Array<any>) {
    let PROYECTS = data;
    if (PROYECTS.length > 0) {
      let active = PROYECTS[0];

      for (let i = 0; i < PROYECTS.length; i++) {
        let summaryTotal = 0;
        const HITOS = PROYECTS[i].boundaryPost;

        for (let j = 0; j < HITOS.length; j++) {
          HITOS[j]['catidadHoras'] = this.numberOfDay(
            PROYECTS[i].boundaryPost[j]['startDate'],
            PROYECTS[i].boundaryPost[j]['endDate']
          );
          summaryTotal =
            summaryTotal +
            this.numberOfDay(
              PROYECTS[i].boundaryPost[j]['startDate'],
              PROYECTS[i].boundaryPost[j]['endDate']
            );
        }
        PROYECTS[i]['totalHoras'] = this.totalDays(HITOS);
      }
      PROYECTS = this.porcetageHito(data);
      return { dataSummary: PROYECTS, active: active };
    }
  }

  /**
   * get the percentage of project
   * @param data
   */
  porcetageHito(data: any) {
    let PROYECTS = data;
    for (let i = 0; i < PROYECTS.length; i++) {
      const HITOS = PROYECTS[i].boundaryPost;

      for (let j = 0; j < HITOS.length; j++) {
        HITOS[j]['porcentage'] =
          (
            (100 / PROYECTS[i]['totalHoras']) *
            HITOS[j]['catidadHoras']
          ).toFixed(2) + '%';
        if (
          HITOS[j]['state'] === 'completado' &&
          HITOS[j + 1]['state'] !== 'completado'
        ) {
          HITOS[j]['textPorcentage'] = true;
        } else {
          HITOS[j]['textPorcentage'] = false;
        }
      }
    }
    return PROYECTS;
  }

  totalDays(hitos): number {
    let total = 0;
    hitos.map((hito) => {
      let pivote = `${
        hito.catidadHoras < 0 ? hito.catidadHoras * -1 : hito.catidadHoras
      }`;
      total = total + parseInt(pivote);
    });
    return total;
  }

  /**
   * get number of days
   * @param fechaInicio
   * @param fechaFin
   * @returns
   */
  numberOfDay(fechaInicio, fechaFin): number {
    var fecha1 = moment(fechaInicio, 'YYYY-MM-DD HH:mm:ss');
    var fecha2 = moment(fechaFin, 'YYYY-MM-DD HH:mm:ss');
    var diff = fecha2.diff(fecha1, 'h'); // Diff in hours
    let contDay = `${diff < 0 ? diff * -1 : diff}`;
    return parseInt(contDay);
  }

  /**
   * summarize by days
   * @param data
   */
  summaryDayProyects(datas: Array<any>) {
    let data = datas;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['component']['code'] == 'PROYECTOS') {
        const PROYECTS = data[i].component.content.project;
        if (PROYECTS.length > 0) {
          let active = PROYECTS[0].id;

          for (let i = 0; i < PROYECTS.length; i++) {
            let summaryTotal = 0;
            const HITOS = PROYECTS[i].boundaryPost;

            for (let j = 0; j < HITOS.length; j++) {
              HITOS[j]['catidadHoras'] = this.numberOfDay(
                PROYECTS[i].boundaryPost[j]['startDate'],
                PROYECTS[i].boundaryPost[j]['endDate']
              );
              summaryTotal =
                summaryTotal +
                this.numberOfDay(
                  PROYECTS[i].boundaryPost[j]['startDate'],
                  PROYECTS[i].boundaryPost[j]['endDate']
                );
            }
            PROYECTS[i]['totalHoras'] = summaryTotal;
          }

          this.porcetageHito(data[i]);
          return { dataSummary: data, active: active };
        }
      }
    }
  }

  /**
   * get the percentage of project
   * @param data
   */
  porcetageHitoProyects(data: any) {
    const PROYECTS = data.component.content.project;
    for (let i = 0; i < PROYECTS.length; i++) {
      const HITOS = PROYECTS[i].boundaryPost;

      for (let j = 0; j < HITOS.length; j++) {
        HITOS[j]['porcentage'] =
          (
            (100 / PROYECTS[i]['totalHoras']) *
            HITOS[j]['catidadHoras']
          ).toFixed(2) + '%';
        if (
          HITOS[j]['state'] === 'completado' &&
          HITOS[j + 1]['state'] !== 'completado'
        ) {
          HITOS[j]['textPorcentage'] = true;
        } else {
          false;
        }
      }
    }
  }
}
