import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { VarRouterPage } from '../setting/varRouterPage';

export class ServeMessage {
  protected eventResponse: HttpResponse<any>;
  protected request: HttpRequest<any>;

  constructor(event?: HttpResponse<any>, request?: HttpRequest<any>) {
    this.eventResponse = event;
    this.request = request;
  }

  showMessage(toast: any, router: Router) {
    const method = this.request.method,
      url = router.url;
    switch (this.eventResponse.status) {
      case 200:
        if (method === 'PUT') {
          this.openSnackBar(
            toast,
            'Se actualizó correctamente',
            'x',
            'success-toast'
          );
        } else if (method === 'POST') {
          this.openSnackBar(
            toast,
            'Se registró correctamente',
            'x',
            'success-toast'
          );
        }
        break;

      case 201:
        if (this.eventResponse.url.indexOf('/payment') <= 0) {
          this.openSnackBar(
            toast,
            'Se registró correctamente',
            'x',
            'success-toast'
          );
        }

        break;
      case 204:
        this.openSnackBar(
          toast,
          'Se eliminó el registro',
          'x',
          'success-toast'
        );
        break;
    }
  }

  openSnackBar(snack: any, message: string, action: string, nameclass: string) {
    snack.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [nameclass],
      duration: 5 * 1000,
    });
  }
}
