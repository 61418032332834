import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-multi-items',
  templateUrl: './skeleton-multi-items.component.html',
  styleUrls: ['./skeleton-multi-items.component.scss']
})
export class SkeletonMultiItemsComponent implements OnInit {

  responsiveOptions = [
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '1000px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '660px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
