
<div class="container-body">

<div id="particles" class="particles">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>

<main>
  <section>
      <h1>
        ¡Página no encontrada!</h1>
      <div>
          <span>4</span>
          <span class="circle">0</span>
          <span>4</span>
      </div>
      <p>No podemos encontrar la página<br>que está buscando.</p>
      <div>
          <button (click)="location.back()">Volver atrás</button>
      </div>
  </section>
</main>
</div>
