<button
  *ngIf="type !== 'back'"
  class="u-flex-center button-icon button-icon--{{ paintButton?.color }}"
  matRipple
  [matRippleColor]="'#ffffff38'"
  (click)="clickBtn.emit()"
  [disabled]="isDisabled"
  [type]="typeButton"
  [ngClass]="{ 'button-icon--disabled': isDisabled }"
>
  <span *ngIf="paintButton.title">{{ paintButton?.title }} </span>
</button>

<button
  *ngIf="type === 'back'"
  class="u-flex-center button-icon button-icon--gray"
  matRipple
  [matRippleColor]="'#ffffff38'"
  (click)="toBack()"
  [disabled]="isDisabled"
  [ngClass]="{ 'button-icon--disabled': isDisabled }"
>
  <span>Atrás</span>
</button>
