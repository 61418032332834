<div
  class="container-search"
  *ngFor="let item of [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]"
>
  <h3 class="skeleton-animation" style="height: 17px; width: 200px"></h3>
  <span class="skeleton-animation" style="height: 17px; width: 100px"></span>
  <p
    class="skeleton-animation"
    style="height: 12px; width: 95%; margin-top: 0px; margin-bottom: 0.6rem"
  ></p>
  <p
    class="skeleton-animation"
    style="height: 12px; width: 90%; margin-top: 7px; margin-bottom: 0.6rem"
  ></p>
  <p
    class="skeleton-animation"
    style="height: 12px; width: 60%; margin-top: 7px; margin-bottom: 0.6rem"
  ></p>
  <div class="container-cards_button u-margin-top-2">
    <div class="skeleton-animation" style="height: 20px; width: 100px"></div>
  </div>
</div>
