import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideCalendarComponent } from './side-calendar.component';
import { FormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarsModule } from '../calendar/calendar.module';
import { MatRippleModule } from '@angular/material/core';
import { SkeletonModule } from '../skeleton/skeleton.module';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalConfirmationModule } from '../modal-confirmation/modal-confirmation.module';
import { TemplateNoDataModule } from '../template-no-data/template-no-data.module';
import { PipeModule } from 'src/app/pipe/pipe.module';

@NgModule({
  declarations: [SideCalendarComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    SkeletonModule,
    CalendarsModule,
    MatRippleModule,
    ModalConfirmationModule,
    TemplateNoDataModule,
    PipeModule
  ],
  exports: [SideCalendarComponent],
  providers: [BsModalService],
})
export class SideCalendarModule {}
