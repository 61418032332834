import { Component, Input, OnInit } from '@angular/core';
import { Ititle } from 'src/app/interfaces/Ititle.interfaces';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
  @Input() dataConfig: Ititle;
  @Input() class: string;

  constructor() {}

  ngOnInit(): void {}
}
