import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VarApi } from 'src/app/setting/varApi';
import { ApiService } from '../interceptors/api.service';

@Injectable({
  providedIn: 'root',
})
export class FilterGeneralService {
  public searchValue$ = new Subject<string>();

  constructor(private _api: ApiService) {}

  /**
   * filter general section restricted
   */
  filterRestricted(criteria: string): Observable<any> {
    return this._api.getDataValues(
      `${VarApi.URL_SEARCH_RESTRICTED}?criteria=${criteria}`
    );
  }
}
