<ngb-carousel
  class="carrucel-initiative"
  [showNavigationArrows]="false"
  [showNavigationIndicators]="true"
>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper-initiative">
      <div class="container-descriptions">
        <div class="skeleton-animation" style="width: 30%; height: 18px"></div>
        <div
          class="skeleton-animation"
          style="width: 50%; height: 12px; margin-top: 5px"
        ></div>
        <div
          class="skeleton-animation"
          style="width: 95%; height: 12px; margin-top: 20px"
        ></div>
        <div
          class="skeleton-animation"
          style="width: 80%; height: 12px; margin-top: 5px"
        ></div>
        <div
          class="skeleton-animation"
          style="width: 80%; height: 12px; margin-top: 5px"
        ></div>
        <div
          class="skeleton-animation"
          style="width: 60%; height: 12px; margin-top: 5px"
        ></div>
        <div class="container-cards_button">
          <div
            class="skeleton-animation"
            style="width: 100px; height: 20px; margin-top: 1rem"
          ></div>
        </div>
      </div>
      <div class="image-initiative skeleton-animation"></div>
    </div>
  </ng-template>
</ngb-carousel>
