<ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="true">
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper-banner">
        <div class="loader-animations">
          <span class="loaders-skeleton"> </span>
        </div>

      <div class="image-banner skeleton-animation" style="padding-top: 42%;"></div>
    </div>
  </ng-template>
</ngb-carousel>
