import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-skeleton-table',
  templateUrl: './skeleton-table.component.html',
  styleUrls: ['../../table-normative/table-normative.component.scss'],
})
export class SkeletonTableComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
