import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Forward } from 'src/app/enum/forward.enum';
import { LocalStorageStg } from 'src/app/setting/localStorageStg';
import { VarApi } from 'src/app/setting/varApi';
import { VarRouterPage } from 'src/app/setting/varRouterPage';
import { AlertMessageService } from '../alert-message/alert-message.service';
import { ApiService } from '../interceptors/api.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public isLogin$ = new Subject<boolean>();
  constructor(
    private _api: ApiService,
    private _router: Router,
    private _alertMessageService: AlertMessageService
  ) {}

  /**
   * Autentication services
   */
  login(dataLogin: any, typeForward?: Forward) {
    this._api
      .postDataValues(`${VarApi.URL_LOGIN}`, dataLogin)
      .subscribe((response) => {
        if (response.hasOwnProperty('status')) {
          if (response['status'] === 'SENDED') {
            localStorage.setItem(
              LocalStorageStg.USER_NAME,
              JSON.stringify(dataLogin)
            );
            this._router.navigate(
              [VarRouterPage.VALIDATE_ACCOUNT],
              typeForward ? { queryParams: { type: typeForward } } : {}
            );
          }
        } else {
          this.sessionStart(response);
        }
      });
  }

  verify(dataVerify: any, typeForward: Forward) {
    this._api
      .postDataValues(`${VarApi.URL_VERIFY}`, dataVerify)
      .subscribe((response) => {
        if (response.user.firstLogin === true) {
          localStorage.setItem(LocalStorageStg.TOKEN, response.accessToken);
          localStorage.setItem(
            LocalStorageStg.USER,
            JSON.stringify(response.user)
          );
          this._router.navigate(
            [VarRouterPage.FIRTLOGIN],
            typeForward ? { queryParams: { type: typeForward } } : {}
          );
        } else {
          this.sessionStart(response, typeForward);
        }
      });
  }

  verifySigup(dataVerify: any): Observable<any> {
    return this._api.postDataValues(`${VarApi.URL_VERIFY_SIGNUP}`, dataVerify);
  }

  resendCode(data: any): Observable<any> {
    return this._api.postDataValues(`${VarApi.URL_RESEND_CODE}`, data);
  }

  /**
   * register session start in localstorage
   * @param data
   */
  sessionStart(data: any, typeForward?: Forward) {
    localStorage.setItem(LocalStorageStg.TOKEN, data.accessToken);
    localStorage.setItem(LocalStorageStg.USER, JSON.stringify(data.user));
    this.forwardPage(typeForward);
    this._alertMessageService.openSnackBar(
      `Bienvenido usuario ${data.user['name']}`,
      'x',
      typeForward ? 'success-toast' : 'success-login-toast'
    );
  }

  forwardPage(typeForward?: Forward) {
    switch (typeForward) {
      case Forward.EVENT:
        this._router.navigate([VarRouterPage.EVENT_REGISTER]);
        break;

      default:
        this._router.navigate([VarRouterPage.HOME_RESTRICTED]);
        break;
    }
  }

  /**
   * close aplications restricted
   */
  logout(typeForward?: Forward) {
    localStorage.clear();
    setTimeout(() => {
      this._router.navigate(
        [VarRouterPage.LOGIN_RESTRICTED],
        typeForward ? { queryParams: { type: typeForward } } : {}
      );
    }, 200);
  }

  /**
   * forgot password
   * @param email
   * @returns
   */
  forgotPassword(email: string): Observable<any> {
    return this._api.postDataValues(
      `${VarApi.URL_USER}/request-reset-password?email=${email}`,
      {}
    );
  }

  /**
   * change password
   * @param email
   * @returns
   */
  changePassword(data: any): Observable<any> {
    return this._api.postDataValues(`${VarApi.URL_USER}/reset-password`, data);
  }

  /**
   * change password firt login
   * @param email
   * @returns
   */
  changePasswordFirstLogin(
    newPassword: string,
    oldPassword: string
  ): Observable<any> {
    return this._api.putDataValues(
      `${VarApi.URL_USER}/change-password?newPassword=${newPassword}&oldPassword=${oldPassword}`,
      {}
    );
  }

  /**
   * Verificamos si es usuario esta logueado
   */
  isLoginUser(): boolean {
    return !!localStorage.getItem(LocalStorageStg.TOKEN);
  }

  /**
   * Verificamos si es usuario esta logueado
   */
  isVerifyUser(): boolean {
    return !!localStorage.getItem(LocalStorageStg.USER_NAME);
  }

  /**
   * Obtener el token
   */
  getUserTokken() {
    return localStorage.getItem(LocalStorageStg.TOKEN);
  }

  /**
   * Obtener nombre de usuario
   */
  getUserName() {
    return localStorage.getItem(LocalStorageStg.USER)['username'];
  }

  /**
   * has a document download role
   */
  isRolACOM() {
    let isExist = false;
    if (localStorage.getItem(LocalStorageStg.USER)) {
      var USER = JSON.parse(localStorage.getItem(LocalStorageStg.USER));
      for (let i = 0; i < USER['roles'].length; i++) {
        if (USER['roles'][i]['name'] === 'ACOM') {
          isExist = true;
          return isExist;
        }
      }
    } else {
      return isExist;
    }
  }
}
