import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-restriction-section',
  templateUrl: './restriction-section.component.html',
  styleUrls: ['./restriction-section.component.scss'],
})
export class RestrictionSectionComponent implements OnInit {
  private readonly ELM: HTMLElement;
  private CONTAINER_ADMIN: HTMLElement | null;
  private SIDEBAR: HTMLElement | null;
  private CALENDAR: HTMLElement | null;
  private BUTTONS: HTMLElement | null;
  private COMPCALENDAR: HTMLElement | null;
  private BUTTONSEARCH: HTMLElement | null;
  private BUTTONPROFILE: HTMLElement | null;

  private isOpenSidebar: boolean;
  public searchRestricted$ = new Subject<boolean>();
  scrHeight: any;
  scrWidth: any;
  urlRouter: String;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.urlRouter !== '/restricted/profile-user') {
      if (this.scrWidth <= 1024) {
        this.actionSidebar(true);
      } else {
        this.actionSidebar(false);
      }
    }
  }

  constructor(
    private _elm: ElementRef,
    private _renderer: Renderer2,
    private _router: Router
  ) {
    this.ELM = this._elm.nativeElement;
    this.CONTAINER_ADMIN = null;
    this.SIDEBAR = null;
    this.CALENDAR = null;
    this.BUTTONS = null;
    this.COMPCALENDAR = null;
    this.BUTTONSEARCH = null;
    this.BUTTONPROFILE = null;
    this.isOpenSidebar = false;
  }
  ngOnInit(): void {
    this.initELM();
    this.getScreenSize();
  }

  initELM() {
    this.CONTAINER_ADMIN = this.ELM.querySelector('.container-restricted');
    this.SIDEBAR = this.ELM.querySelector('.content-side-calendar');
    this.CALENDAR = this.ELM.querySelector('.content-side-calendar');
    this.BUTTONS = this.ELM.querySelector('.button-colpase');
    this.COMPCALENDAR = this.ELM.querySelector('.calendar-content');
    this.BUTTONSEARCH = this.ELM.querySelector('.form_search');
    this.BUTTONPROFILE = this.ELM.querySelector('.button-profile');
  }

  actionSidebar($event: boolean, responsive?: boolean): void {
    this.isOpenSidebar = $event;
    if (this.isOpenSidebar) {
      this._renderer.setStyle(this.CONTAINER_ADMIN, 'width', `100%`);
      this._renderer.setStyle(this.CONTAINER_ADMIN, 'right', '0px');
      this._renderer.addClass(this.CALENDAR, 'colapse-side-calendar');
      this._renderer.addClass(this.BUTTONS, 'button-colpase-show');
      this._renderer.setStyle(this.COMPCALENDAR, 'display', 'none');
      if (responsive) {
        this._renderer.setStyle(this.SIDEBAR, 'transition', 'none');
      } else {
        this._renderer.setStyle(
          this.SIDEBAR,
          'transition',
          'width 100ms cubic-bezier(0.42, 0.01, 0.4, 1)'
        );
      }
      if (this.BUTTONSEARCH) {
        this._renderer.setStyle(this.BUTTONSEARCH, 'display', 'none');
      }
      this._renderer.setStyle(this.BUTTONPROFILE, 'display', 'none');

      setTimeout(() => {
        this._renderer.setStyle(this.CALENDAR, 'z-index', '-99');
      }, 200);
    } else {
      this._renderer.removeStyle(this.CONTAINER_ADMIN, 'width');
      this._renderer.removeStyle(this.CONTAINER_ADMIN, 'right');
      this._renderer.removeStyle(this.CALENDAR, 'z-index');
      if (this.BUTTONSEARCH) {
        this._renderer.removeStyle(this.BUTTONSEARCH, 'display');
      }
      this._renderer.removeStyle(this.BUTTONPROFILE, 'display');
      this._renderer.removeClass(this.BUTTONS, 'button-colpase-show');
      setTimeout(() => {
        this._renderer.removeClass(this.CALENDAR, 'colapse-side-calendar');
      }, 50);
      setTimeout(() => {
        this._renderer.setStyle(this.COMPCALENDAR, 'display', 'block');
      }, 100);
      this._renderer.removeStyle(this.SIDEBAR, 'width');
    }
  }

  /**
   * active router oulet
   */
  onActivate() {
    this.validateProfile();
    const contentContainer =
      document.querySelector('.container-restricted') || window;
    contentContainer.scrollTo(0, 0);
    if (this._router.url.indexOf('/restricted/home') >= 0) {
      this.searchRestricted$.next(true);
    } else {
      this.searchRestricted$.next(false);
    }
  }

  validateProfile() {
    this.initELM();
    this.urlRouter = this._router.url;
    if (this.urlRouter === '/restricted/profile-user') {
      this.actionSidebar(true);
      this._renderer.setStyle(this.BUTTONS, 'visibility', 'hidden');
      this._renderer.setStyle(this.SIDEBAR, 'display', 'none');
      this._renderer.setStyle(this.CONTAINER_ADMIN, 'padding-right', '0px');
    } else {
      this.scrWidth = window.innerWidth;
      if (this.scrWidth <= 1024) {
        this.actionSidebar(true, true);
      } else {
        this._renderer.removeStyle(this.SIDEBAR, 'transition');
        this.actionSidebar(false);
      }
      this._renderer.removeStyle(this.BUTTONS, 'visibility');
      this._renderer.removeStyle(this.SIDEBAR, 'display');
      this._renderer.setStyle(this.CONTAINER_ADMIN, 'padding-right', '320px');
    }
  }
}
