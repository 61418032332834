import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { FooterComponent, NavbarComponent } from 'src/app/components';
import { HomeService } from 'src/app/services/public-services/home.service';
import { PagesComponent } from './pages.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoginGuard } from 'src/app/security/login-guard.guard';
import { AuthGuard } from 'src/app/security/auth.guard';
const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'home',
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'institucional',
        loadChildren: () =>
          import('./institucional/institucional.module').then(
            (m) => m.InstitucionalModule
          ),
      },
      {
        path: 'publications',
        loadChildren: () =>
          import('./publications/publications.module').then(
            (m) => m.PublicationsModule
          ),
      },
      {
        path: 'press-release',
        loadChildren: () =>
          import('./press-realease/press-realease.module').then(
            (m) => m.PressRealeaseModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./event/event.module').then((m) => m.EventModule),
      },
      {
        path: 'initiatives',
        loadChildren: () =>
          import('./initiatives/initiatives.module').then(
            (m) => m.InitiativesModule
          ),
      },
      {
        path: 'event-registration',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () =>
          import('./event/event-registration/event-registration.module').then(
            (m) => m.EventRegistrationModule
          ),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'FAQ',
        loadChildren: () => import('./FAQ/FAQ.module').then((m) => m.FaqModule),
      },
    ],
  },
];

@NgModule({
  declarations: [PagesComponent, NavbarComponent, FooterComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    MatTooltipModule,
    SharedModule,
  ],
  exports: [RouterModule],
  providers: [HomeService],
})
export class PagesRoutingModule {}
