import { Component, Input, OnInit } from '@angular/core';
import { IBannerImage } from 'src/app/interfaces/IBannerImage.interfaces';

@Component({
  selector: 'app-banner-images',
  templateUrl: './banner-images.component.html',
})
export class BannerImagesComponent implements OnInit {
  @Input() configData: IBannerImage;
  constructor() {}

  ngOnInit(): void {}
}
