<div
  class="grid-col-3"
  [ngClass]="{ 'grid-col-3': col === '3', 'grids-col-2': col === '2' }"
>
  <div
    class="container-cards"
    *ngFor="let item of counter(arrayNumber)"
  >
    <div class="skeleton-animation" style="width: 100px; height: 100px"></div>
    <span
      style="height: 12px; width: 35%; margin-top: 10px"
      class="skeleton-animation"
    ></span>
    <div
      style="height: 12px; width: 85%; margin-top: 1rem"
      class="skeleton-animation"
    ></div>
    <div
      style="height: 12px; width: 60%; margin-top: 5px"
      class="skeleton-animation"
    ></div>
    <div
      style="height: 12px; width: 40%; margin-top: 5px"
      class="skeleton-animation"
    ></div>

    <div class="container-cards_button" *ngIf="button">
      <div
        style="height: 20px;width: 80px; margin-top: 10px"
        class="skeleton-animation"
      ></div>
    </div>
  </div>
</div>
