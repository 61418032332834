<ng-container *ngIf="type === 'notice'">
  <div class="container-note" *ngFor="let item of [1, 1]">
    <div class="container-note_icon">
      <svg class="skeleton-animation"></svg>
    </div>

    <div class="container-note_description">
      <h3 class="skeleton-animation" style="width: 30%; height: 15px"></h3>
      <div
        class="skeleton-animation"
        style="width: 40%; height: 12px; margin-top: 5px"
      ></div>
      <div style="width: 100%; margin-top: 10px">
        <p
          class="skeleton-animation"
          style="width: 90%; height: 10px; margin-bottom: 5px"
        ></p>
        <p
          class="skeleton-animation"
          style="width: 90%; height: 10px; margin-bottom: 5px"
        ></p>
        <p
          class="skeleton-animation"
          style="width: 60%; height: 10px; margin-bottom: 5px"
        ></p>
      </div>

      <div class="container-cards_button">
        <div class="skeleton-animation" style="width: 40%; height: 18px"></div>
      </div>
    </div>
  </div>
</ng-container>

<!-- publicaciones -->
<ng-container *ngIf="type === 'publication'">
  <div class="container-publication" *ngFor="let item of [1, 1]">
    <svg class="container-publication_icon skeleton-animation"></svg>
    <span
      class="container-publication_title skeleton-animation"
      style="width: 70%; height: 12px"
    ></span>
  </div>
</ng-container>
<!-- agenda de eventos proximos -->
<ng-container *ngIf="type === 'event'">
  <div class="container-next-event" *ngFor="let item of [8, 4]">
    <div class="container-next-event_image skeleton-animation"></div>
    <div class="container-next-event_description">
      <h3 class="skeleton-animation" style="width: 80%; height: 20px"></h3>
      <span class="skeleton-animation" style="width: 50%; height: 15px"></span>
      <div class="container-cards_button u-flex-center">
        <div class="skeleton-animation" style="width: 60%; height: 18px"></div>
      </div>
    </div>
  </div>
</ng-container>
