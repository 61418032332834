<div
  class="container-carrucel-events"
  style="background-color: var(--color-tertiary-gray)"
>
  <ngb-carousel
    class="carrucel-events"
    [showNavigationArrows]="false"
    [showNavigationIndicators]="true"
  >
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper-event skeleton-animation">
        <div
          class="imagen-carrucel-event skeleton-animation"
          style="background-color: var(--color-tertiary-gray)"
        ></div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
