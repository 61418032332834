<div class="modal_responsive" style="height: auto !important">
  <header class="modal_responsive__header">
    <span class="in-right">{{ config.titleModal || "" }}</span>
    <button
      (click)="closeModal()"
      [matRippleColor]="'rgba(255, 255, 255, 0.2)'"
      type="submit"
      matRipple
      class="modal_responsive__header--close"
    >
      <svg
        class="modal_responsive__header--icon"
        height="10px"
        viewBox="0 0 329.26933 329"
        width="10px"
      >
        <use href="assets/svg/icons.svg#svg-close"></use>
      </svg>
    </button>
  </header>
  <div class="modal_responsive__body">
    <div class="container u-padding-top-10">
      <ng-container [ngTemplateOutlet]="config.templateBody"> </ng-container>
    </div>
  </div>
  <footer class="modal_responsive__footer">
    <ng-container [ngTemplateOutlet]="config.templateFooter"> </ng-container>
  </footer>
</div>

<div class="modal_responsive__confirm">
  <ng-container [ngTemplateOutlet]="config.templateConfirmModal">
  </ng-container>
</div>
