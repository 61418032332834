import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ReferenceType } from 'src/app/enum/referenceType';
import { VarRouterPage } from 'src/app/setting/varRouterPage';

@Component({
  selector: 'app-carrucel',
  templateUrl: './carrucel.component.html',
  styleUrls: ['./carrucel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarrucelComponent implements OnInit {
  @Input() dataCarrucel: Array<any>;
  @Input() timeInterval: any;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  redirectTo(data: any) {
    switch (data.referenceType) {
      case 'CONTENT':
        if (data.urlWeb) {
          this._router.navigate([
            `${VarRouterPage.DETAIL_PRESS_RELEASE}/${data.urlWeb}`,
          ]);
        }
        break;
      case 'EVENT':
        if (data.eventId) {
          this._router.navigate([
            `${VarRouterPage.DETAIL_EVENTS}/${data.eventId}`,
          ]);
        }
        break;
      case 'EXTERNAL':
        if (data.urlExternal) {
          window.open(data.urlExternal, '_blank');
        }
        break;
    }
  }
}
