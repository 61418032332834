import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatRippleModule} from '@angular/material/core';
import { CardOptionsComponent } from './card-options.component';

@NgModule({
  declarations: [CardOptionsComponent],
  imports: [
    CommonModule,
    MatRippleModule
  ],
  exports:[CardOptionsComponent]
})
export class CardOptionsModule { }
