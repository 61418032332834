import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'img[default]',
  host: {
    '(error)':'updateUrl()',
    '(load)': 'load()',
    '[src]':'src'
   }
})
export class ImageBrokenDirective {

  @Input() appImageFallback:string
  constructor(
  ) { }
@Input() src:string;
@Input() default:string;
@HostBinding('class') className

updateUrl() {
  this.src = this.default;
}
load(){
  this.className = 'size-image-mini u-object-fit u-w-h-100';
}
}
