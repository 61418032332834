import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-carrusel-initiatives',
  templateUrl: './skeleton-carrusel-initiatives.component.html',
  styleUrls: ['./skeleton-carrusel-initiatives.component.scss']
})
export class SkeletonCarruselInitiativesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
