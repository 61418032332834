<div class="container-multiitems">
  <p-carousel
    [value]="[1, 1, 1, 1, 1]"
    styleClass="custom-carousel"
    [numVisible]="3"
    [numScroll]="3"
    [circular]="true"
    [autoplayInterval]="3000"
    [responsiveOptions]="responsiveOptions"
  >
    <ng-template let-item pTemplate="item">
      <div class="product-item">
        <div class="product-item-content">
          <div class="container-slider-item">
            <div
              class="container-slider-item_images u-cursor-pointer"
              matRipple
              [matRippleColor]="'#c8ccd50f'"
              (click)="onClick.emit(item)"
            >
              <div  width="300px" height="200px" class="skeleton-animation image" ></div>
            </div>
            <h3
              class="container-slider-item_title skeleton-animation"
              style="width: 40%; height: 18px"
            ></h3>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>
