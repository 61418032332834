import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ICategories } from 'src/app/interfaces/components/ICategories.interfaces';
import { ISocialMedia } from 'src/app/interfaces/components/ISocialMedia.interfaces';
import { PagesComponent } from 'src/app/pages/public-section/pages.component';
import { HomeService } from 'src/app/services/public-services/home.service';
import { LoginService } from 'src/app/services/restricted-services/login.service';
import { LocalStorageStg } from 'src/app/setting/localStorageStg';
import { VarRouterPage } from 'src/app/setting/varRouterPage';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  dataNav: Array<ICategories> = [];
  isShowingInputSearch = false;
  ELM: HTMLElement;
  checked: boolean = false;
  public val = '';
  dataSocial: Array<ISocialMedia> = [];
  isLogin: boolean;
  nameUser = JSON.parse(localStorage.getItem(LocalStorageStg.USER));
  showSearch: boolean;

  constructor(
    private _renderer: Renderer2,
    private _elm: ElementRef,
    private _router: Router,
    private _homeService: HomeService,
    private _loginService: LoginService,
    private _pagesComponent: PagesComponent
  ) {
    this.ELM = this._elm.nativeElement;
    this.isLogin = this._loginService.isLoginUser();
    this.showSearch =
      this._router.url.indexOf('/public/search') >= 0 ? false : true;
  }

  ngOnInit(): void {
    this._loginService.isLogin$.subscribe((response) => {
      this.isLogin = response;
    });

    this._pagesComponent.searchGeneral$.subscribe((response) => {
      this.showSearch = response;
    });

    this.dataSocialMedia();
    this.dataNavigation();
  }

  /**
   * get data social media
   */
  dataSocialMedia() {
    this._homeService.getSocialMedia().subscribe((response) => {
      this.dataSocial = response;
    });
  }

  dataNavigation() {
    this._homeService.getNavigationCategories().subscribe((response) => {
      this.dataNav = response;
      for (let i = 0; i < this.dataNav.length; i++) {
        let data = this.dataNav[i];
        switch (data['id']) {
          case 1:
            localStorage.setItem(
              LocalStorageStg.INSTITUTIONAL,
              JSON.stringify(data)
            );
            break;
          case 2:
            localStorage.setItem(
              LocalStorageStg.PUBLICATION,
              JSON.stringify(data)
            );
            break;
          case 3:
            localStorage.setItem(LocalStorageStg.PRESS, JSON.stringify(data));
            break;
          case 4:
            localStorage.setItem(LocalStorageStg.EVENT, JSON.stringify(data));
            break;
          case 5:
            localStorage.setItem(
              LocalStorageStg.INITIATIVE,
              JSON.stringify(data)
            );
            break;
          case 6:
            localStorage.setItem(
              LocalStorageStg.CONTACT,
              JSON.stringify(data)
            );
            break;
        }
      }
    });
  }

  /**
   * Abrir u ocultar el input buscador
   */
  openSearch(): void {
    const inputRef = this.ELM.querySelector('.form_search__input');
    this._renderer.addClass(inputRef, 'form_search__input--show');
    if (this.isShowingInputSearch) {
      this._renderer.removeClass(inputRef, 'form_search__input--show');
      this._renderer.addClass(inputRef, 'form_search__input--hidden');
    } else {
      this._renderer.addClass(inputRef, 'form_search__input--show');
      this._renderer.removeClass(inputRef, 'form_search__input--hidden');
    }
    this.isShowingInputSearch = !this.isShowingInputSearch;
  }

  public search() {
    this.redirectToPage();
    this.openSearch();
    if (this.val != '') {
      this._router.navigate([VarRouterPage.SEARCH], {
        queryParams: { search: this.val },
      });
    }
    this.val = '';
  }

  redirectToPage(data?: ICategories) {
    this.checked = false;
  }

  // redirect to home
  goTohome() {
    this._router.navigate([VarRouterPage.HOME]);
  }

  /**
   * redirect to login
   */
  login() {
    this._router.navigate([VarRouterPage.LOGIN_RESTRICTED]);
  }

  /**
   * redirect to social media
   * @param url
   */
  redirectSocialMedia(url: string) {
    window.open(url, '_blank');
  }

  redirectTorestricted() {
    this._router.navigate([VarRouterPage.HOME_RESTRICTED]);
  }
}
